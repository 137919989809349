import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import UserService from '../../services/user.service';
import {
  clearOrder,
  setOrder,
  setSelectedSideModel,
  setTypeModel,
} from '../../store/reducers/Project/ProjectSlice';
import {
  useAddQuoteMutation,
  useGenerateNumber,
  // useAddQuoteWithoutAuthMutation,
} from '../../services/quotes.service';
import {
  useAddProjectMutation,
  useGetProductsPriceQuery,
  useUpdateProjectMutation,
  useAddProjectWithoutAuthMutation,
} from '../../services/projects.service';
import {
  setComment,
  setSelectedProject,
  // setShowIframeModal,
  setShowLoginModal,
} from '../../store/reducers/User/UserSlice';

const SidePanel = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.user.activeStep);
  const { profile, profile2, selectedProject, expertMode, comment } =
    useSelector((state) => state.user);
  const { source, typeModel, order } = useSelector((state) => state.project);
  const { data: productPrice, isLoading } = useGetProductsPriceQuery({
    products: order,
  });

  const [, setSection] = useState(null);
  const [deliveryData, setDeliveryData] = useState({});
  const [deliveryFlag, setDeliveryFlag] = useState(false);
  const [, setSideWall] = useState(false);
  const [, setPriceUpdate] = useState(true);

  const [totalVal, setTotalVal] = useState(0);

  const [, setTitle] = useState(false);

  useEffect(() => {
    setSelectedSideModel(null);
    if (source) {
      source.findSide(null);
    }
  }, [expertMode]);

  const delivery = async () => {
    if (!profile) return { distance: 0, duration: 0 };
    try {
      const response = await UserService.postDelivery({
        postcode: profile.postcode,
      });
      return {
        distance: response?.rows[0]?.elements[0]?.distance.value,
        duration: response?.rows[0]?.elements[0]?.duration.value,
      };
    } catch (err) {
      console.error(err);
      return { distance: 0, duration: 0 };
    }
  };

  useEffect(() => {
    if (!source) return;
    source?.addEventListener('new-type-model', (data) => {
      dispatch(setTypeModel(data.model));
    });

    source?.addEventListener('open-section1', () => {
      setSection('section1');
    });

    source?.addEventListener('update-price', async (data) => {
      setPriceUpdate(false);
      dispatch(clearOrder());
      // extrass
      if (data.extras.servicesTG.planung) {
        dispatch(
          setOrder({
            id: '1008',
            qty: 1,
          })
        );
        dispatch(
          setOrder({
            id: '1544',
            qty: 1,
          })
        );
      }
      if (data.extras.servicesTG.montagePlanung) {
        dispatch(
          setOrder({
            id: '1005',
            qty: 1,
          })
        );
        dispatch(
          setOrder({
            id: '1008',
            qty: 1,
          })
        );
        dispatch(
          setOrder({
            id: '1544',
            qty: 1,
          })
        );
      }
      if (data.extras.servicesTG.baugensuch) {
        dispatch(
          setOrder({
            id: '1090',
            qty: 1,
          })
        );
      }
      if (data.extras.servicesTG.aufmassVorOrt) {
        dispatch(
          setOrder({
            id: '1551',
            qty: 1,
          })
        );
      }
      if (data.extras.externalServicesTG.fundamente) {
        dispatch(
          setOrder({
            id: '1094',
            qty: data.extras.externalServicesTG.colFundamente,
          })
        );
      }
      if (data.extras.externalServicesTG.plattenpassen) {
        dispatch(
          setOrder({
            id: '1097',
            qty: data.extras.externalServicesTG.colPlattenpassen,
          })
        );
      }
      // elements
      data.elements.forEach((el) => {
        if (el.model.type === 'louver') {
          dispatch(
            setOrder({
              id: '1061',
              qty: 1,
              Variables: [
                {
                  VariableName: 'Breite',
                  VariableValue: +(data.size.w / 10),
                },
                {
                  VariableName: 'Tiefe',
                  VariableValue: +(data.size.d / 10),
                },
                {
                  VariableName: 'HoeheOKT',
                  VariableValue: +(data.size.h1 / 10),
                },
              ],
            })
          );
        }

        if (el.model.type === 'louver2') {
          dispatch(
            setOrder({
              id: '1064',
              qty: 1,
              Variables: [
                {
                  VariableName: 'Breite',
                  VariableValue: +(data.size.w / 10),
                },
                {
                  VariableName: 'Tiefe',
                  VariableValue: +(data.size.d / 10),
                },
                {
                  VariableName: 'HoeheOKT',
                  VariableValue: +(data.size.h1 / 10),
                },
              ],
            })
          );
        }
        if (el.model.type === 'glass') {
          dispatch(
            setOrder({
              id: '1001',
              qty: 1,
              Variables: [
                {
                  VariableName: 'Breite',
                  VariableValue: +(data.size.w / 10),
                },
                {
                  VariableName: 'Tiefe',
                  VariableValue: +(data.size.d / 10),
                },
                {
                  VariableName: 'HoeheUKT',
                  VariableValue: +(data.size.h1 / 10),
                },
                {
                  VariableName: 'HoeheOKW',
                  VariableValue: +(data.size.h2 / 10),
                },
              ],
            })
          );
          dispatch(
            setOrder({
              id: '1055',
              qty: 1,
            })
          );
        }
        el.arrLed.forEach((item) => {
          if (item !== undefined) {
            if (item.type === 'direct' || item.type === 'inderect') {
              let color = 0;
              let seiten = 0;
              if (item.color === 'white') {
                color = 0;
              }
              if (item.color === 'rgb') {
                color = 1;
              }
              if (item.seiten === 'seiten-4') {
                seiten = 0;
              }
              if (item.seiten === 'seiten-2') {
                seiten = 1;
              }
              dispatch(
                setOrder({
                  id: '1547',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.w / 10),
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +(data.size.d / 10),
                    },
                    {
                      VariableName: 'RGB',
                      VariableValue: color,
                    },
                    {
                      VariableName: 'NurSeiten',
                      VariableValue: seiten,
                    },
                  ],
                })
              );
            }

            if (item.type === 'spots') {
              dispatch(
                setOrder({
                  id: '1250',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.w / 10),
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +el.model.sparrenLenght.toFixed(2),
                    },
                    {
                      VariableName: 'Sparren',
                      VariableValue: +item.sparren,
                    },
                    {
                      VariableName: 'LEDproSparren',
                      VariableValue: +item.spots,
                    },
                  ],
                })
              );
            }
          }
        });
        el.arrWindow.forEach((item) => {
          if (item.type === 'windowType1' && el.model.type === 'louver') {
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  section: el.colSectionWindow[item.side],
                  side: item.side,
                  id: '1553',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 20,
                    },
                    {
                      VariableName: 'HoeheUKT',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                    {
                      VariableName: 'FFAnzahlElemente',
                      VariableValue: el.colSectionWindow[item.side],
                    },
                  ],
                })
              );
              dispatch(
                setOrder({
                  side: item.side,
                  squar:
                    ((+data.size.d / 10 - 20) * (+data.size.h1 / 10 - 20)) /
                    10000,
                  id: '1025',
                  qty: 1,
                })
              );
            }

            if (item.side === 'sideB' || item.side === 'sideD') {
              dispatch(
                setOrder({
                  section: el.colSectionWindow[item.side],
                  side: item.side,
                  id: '1553',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.w / 10) - 40,
                    },
                    {
                      VariableName: 'HoeheUKT',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                    {
                      VariableName: 'FFAnzahlElemente',
                      VariableValue: el.colSectionWindow[item.side],
                    },
                  ],
                })
              );
              dispatch(
                setOrder({
                  side: item.side,
                  squar:
                    ((+data.size.w / 10 - 40) * (+data.size.h1 / 10 - 20)) /
                    10000,
                  id: '1025',
                  qty: 1,
                })
              );
            }
          }
          if (item.type === 'windowType1' && el.model.type === 'glass') {
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  section: el.colSectionWindow[item.side],
                  side: item.side,
                  id: '1554',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 13.5,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(data.size.h2 / 10),
                    },
                    {
                      VariableName: 'AnzahlElementeinderBreite',
                      VariableValue: el.colSectionWindow[item.side],
                    },
                    {
                      VariableName: 'AnzahlElementeinderHoehe',
                      VariableValue: 2,
                    },
                  ],
                })
              );

              dispatch(
                setOrder({
                  side: item.side,
                  squar:
                    ((+data.size.d / 10 - 13.5) * (+data.size.h2 / 10)) / 10000,
                  id: '1058',
                  qty: 1,
                })
              );
            }
            if (item.side === 'sideB') {
              if (+data.size.w > 7000 + 200) {
                dispatch(
                  setOrder({
                    section: el.colSectionWindow[item.side],
                    side: item.side,
                    id: '1554',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +(data.size.w / 10 / 2) - 20,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +(data.size.h1 / 10) - 15,
                      },
                      {
                        VariableName: 'AnzahlElementeinderBreite',
                        VariableValue: el.colSectionWindow[item.side],
                      },
                      {
                        VariableName: 'AnzahlElementeinderHoehe',
                        VariableValue: 1,
                      },
                    ],
                  })
                );
                dispatch(
                  setOrder({
                    side: item.side,
                    squar:
                      ((+data.size.w / 10 / 2 - 20) *
                        (+data.size.h1 / 10 - 15)) /
                      10000,
                    id: '1058',
                    qty: 2,
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    section: el.colSectionWindow[item.side],
                    side: item.side,
                    id: '1554',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +(data.size.w / 10) - 20,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +(data.size.h1 / 10) - 15,
                      },
                      {
                        VariableName: 'AnzahlElementeinderBreite',
                        VariableValue: el.colSectionWindow[item.side],
                      },
                      {
                        VariableName: 'AnzahlElementeinderHoehe',
                        VariableValue: 1,
                      },
                    ],
                  })
                );
                dispatch(
                  setOrder({
                    side: item.side,
                    squar:
                      ((+data.size.w / 10 - 20) * (+data.size.h1 / 10 - 15)) /
                      10000,
                    id: '1058',
                    qty: 1,
                  })
                );
              }
            }
          }
          if (item.type === 'windowType2' && el.model.type === 'louver') {
            let idProduct = null;
            if (el.colSectionWindow[item.side] === 2) {
              idProduct = '1134';
            }
            if (el.colSectionWindow[item.side] === 3) {
              idProduct = '1135';
            }
            if (el.colSectionWindow[item.side] === 4) {
              idProduct = '1136';
            }
            if (el.colSectionWindow[item.side] === 5) {
              idProduct = '1137';
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              dispatch(
                setOrder({
                  side: item.side,
                  id: idProduct,
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.w / 10) - 40,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                  ],
                })
              );
            }
            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  side: item.side,
                  id: idProduct,
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 20,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(data.size.h1 / 10) - 20,
                    },
                  ],
                })
              );
            }
          }
          if (item.type === 'windowType2' && el.model.type === 'glass') {
            let idProduct = null;
            if (el.colSectionWindow[item.side] === 2) {
              idProduct = '1045';
            }
            if (el.colSectionWindow[item.side] === 3) {
              idProduct = '1049';
            }
            if (el.colSectionWindow[item.side] === 4) {
              idProduct = '1048';
            }
            if (el.colSectionWindow[item.side] === 5) {
              idProduct = '1040';
            }

            if (item.side === 'sideB') {
              if (+data.size.w > 7500 + 200) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: idProduct,
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +(data.size.w / 10 / 2) - 20,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +(data.size.h1 / 10) - 15,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: idProduct,
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +(data.size.w / 10) - 20,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +(data.size.h1 / 10) - 15,
                      },
                    ],
                  })
                );
              }
            }

            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  side: item.side,
                  id: idProduct,
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 13.5,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(data.size.h1 / 10) - 15,
                    },
                  ],
                })
              );
            }

            if (item.side === 'sideA' || item.side === 'sideC') {
              dispatch(
                setOrder({
                  section: el.colSectionWindow[item.side],
                  side: item.side,
                  id: '1554',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +(data.size.d / 10) - 13.5,
                    },
                    {
                      VariableName: 'Hoehe',
                      VariableValue: +(
                        data.size.h2 / 10 -
                        +(data.size.h1 / 10) +
                        15
                      ),
                    },
                    {
                      VariableName: 'AnzahlElementeinderBreite',
                      VariableValue: el.colSectionWindow[item.side],
                    },
                    {
                      VariableName: 'AnzahlElementeinderHoehe',
                      VariableValue: 1,
                    },
                  ],
                })
              );

              dispatch(
                setOrder({
                  side: item.side,
                  squar:
                    ((+data.size.d / 10 - 13.5) *
                      (+data.size.h2 / 10 - +data.size.h1 / 10 + 15)) /
                    10000,
                  id: '1058',
                  qty: 1,
                })
              );
            }
          }
        });

        el.arrZip.forEach((item) => {
          if (
            item.type === 'zipType1' &&
            el.model.type === 'louver' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6082 + 200 - 80) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 - 20 + 8) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.d / 10 - 20 + 8,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6082 + 400 - 80) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.w / 10 - 40 + 8) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 - 40 + 8,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              }
            }
          }

          if (
            item.type === 'zipType1' &&
            el.model.type === 'louver2' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6082 + 100 - 80) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 - 10 + 8) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.d / 10 - 10 + 8,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6082 + 300 - 80) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.w / 10 - 30 + 8) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 - 30 + 8,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20 + 14,
                      },
                    ],
                  })
                );
              }
            }
          }

          if (
            item.type === 'zipType2' &&
            el.model.type === 'louver' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6082 + 200) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 - 20) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.d / 10 - 20,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6082 + 400) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 / 2 - 40,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1059',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10 - 40,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 - 20,
                      },
                    ],
                  })
                );
              }
            }
          }
          if (
            (item.type === 'zipType1' || item.type === 'zipType3') &&
            el.model.type === 'glass' &&
            item.side === 'sideRoof'
          ) {
            if (+data.size.w > 6000) {
              dispatch(
                setOrder({
                  id: '1477',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue:
                        +data.size.w / 10 - el.model.zipRoofSeparation * 100,
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +data.size.d / 10,
                    },
                  ],
                })
              );
              dispatch(
                setOrder({
                  id: '1477',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +el.model.zipRoofSeparation * 100,
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +data.size.d / 10,
                    },
                  ],
                })
              );
            } else {
              dispatch(
                setOrder({
                  id: '1477',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +data.size.w / 10,
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +data.size.d / 10,
                    },
                  ],
                })
              );
            }
          }
          if (
            (item.type === 'zipType2' || item.type === 'zipType4') &&
            el.model.type === 'glass' &&
            item.side === 'sideRoof'
          ) {
            if (+data.size.w > 5000) {
              if (+data.size.w > 8000) {
                dispatch(
                  setOrder({
                    id: '1036',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue:
                          +data.size.w / 10 - el.model.zipRoofSeparation * 100,
                      },
                      {
                        VariableName: 'Tiefe',
                        VariableValue: +data.size.d / 10,
                      },
                    ],
                  })
                );
                dispatch(
                  setOrder({
                    id: '1036',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +el.model.zipRoofSeparation * 100,
                      },
                      {
                        VariableName: 'Tiefe',
                        VariableValue: +data.size.d / 10,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    id: '1037',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: +data.size.w / 10,
                      },
                      {
                        VariableName: 'Tiefe',
                        VariableValue: +data.size.d / 10,
                      },
                    ],
                  })
                );
              }
            } else {
              dispatch(
                setOrder({
                  id: '1036',
                  qty: 1,
                  Variables: [
                    {
                      VariableName: 'Breite',
                      VariableValue: +data.size.w / 10,
                    },
                    {
                      VariableName: 'Tiefe',
                      VariableValue: +data.size.d / 10,
                    },
                  ],
                })
              );
            }
          }
          if (
            (item.type === 'zipType1' || item.type === 'zipType3') &&
            el.model.type === 'glass' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6000 + 135) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1096',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 13.5) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1096',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 13.5) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6000 + 200) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1096',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.w / 10 + 20) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1096',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.w / 10 + 20) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              }
            }
          }
          if (
            (item.type === 'zipType2' || item.type === 'zipType4') &&
            el.model.type === 'glass' &&
            item.side !== 'sideRoof'
          ) {
            if (item.side === 'sideA' || item.side === 'sideC') {
              if (+data.size.d > 6000 + 135) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1117',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 13.5) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1117',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 13.5) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              }
            }
            if (item.side === 'sideB' || item.side === 'sideD') {
              if (+data.size.w > 6000 + 200) {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1117',
                    qty: 2,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 20) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              } else {
                dispatch(
                  setOrder({
                    side: item.side,
                    id: '1117',
                    qty: 1,
                    Variables: [
                      {
                        VariableName: 'Breite',
                        VariableValue: (+data.size.d / 10 + 20) / 2,
                      },
                      {
                        VariableName: 'Hoehe',
                        VariableValue: +data.size.h1 / 10 + 20,
                      },
                    ],
                  })
                );
              }
            }
          }
        });
        el.arrLamellen.forEach((item) => {
          if (item.side === 'sideA' || item.side === 'sideC') {
            dispatch(
              setOrder({
                sectionLenght: el.lamellenWidth[item.side] * 100,
                section:
                  el.colSectionLamellen[item.side][0] +
                  el.colSectionLamellen[item.side][1],
                side: item.side,
                id: '1546',
                qty: 1,
                Variables: [
                  {
                    VariableName: 'Anlagenbreite',
                    VariableValue: +(data.size.d / 10) - 20,
                  },
                  {
                    VariableName: 'Anlagenhoehe',
                    VariableValue: +(data.size.h1 / 10) - 20,
                  },
                  {
                    VariableName: 'AnzahlSchiebelaeden',
                    VariableValue:
                      el.colSectionLamellen[item.side][0] +
                      el.colSectionLamellen[item.side][1],
                  },
                  {
                    VariableName: 'Schiebeladenbreite',
                    VariableValue: el.lamellenWidth[item.side] * 100,
                  },
                  {
                    VariableName: 'AnzahlLaufschienen',
                    VariableValue: 2,
                  },
                ],
              })
            );
          }
          if (item.side === 'sideB' || item.side === 'sideD') {
            dispatch(
              setOrder({
                sectionLenght: el.lamellenWidth[item.side] * 100,
                section:
                  el.colSectionLamellen[item.side][0] +
                  el.colSectionLamellen[item.side][1],
                side: item.side,
                id: '1546',
                qty: 1,
                Variables: [
                  {
                    VariableName: 'Anlagenbreite',
                    VariableValue: +(data.size.w / 10) - 40,
                  },
                  {
                    VariableName: 'Anlagenhoehe',
                    VariableValue: +(data.size.h1 / 10) - 20,
                  },
                  {
                    VariableName: 'AnzahlSchiebelaeden',
                    VariableValue:
                      el.colSectionLamellen[item.side][0] +
                      el.colSectionLamellen[item.side][1],
                  },
                  {
                    VariableName: 'Schiebeladenbreite',
                    VariableValue: el.lamellenWidth[item.side] * 100,
                  },
                  {
                    VariableName: 'AnzahlLaufschienen',
                    VariableValue: 2,
                  },
                ],
              })
            );
          }
        });
        el.arrSteering.forEach((item) => {
          if (item === 'steeringType1') {
            dispatch(
              setOrder({
                id: '1165',
                qty: 1,
              })
            );
          }
          if (item === 'steeringType2') {
            dispatch(
              setOrder({
                id: '1184',
                qty: 1,
              })
            );
          }
          if (item === 'steeringType3') {
            dispatch(
              setOrder({
                id: '1321',
                qty: 1,
              })
            );
          }
          if (item === 'steeringType4') {
            dispatch(
              setOrder({
                id: '1099',
                qty: 1,
              })
            );
          }
        });
        el.arrElectro.forEach((item) => {
          if (item === 'electroType1') {
            dispatch(
              setOrder({
                id: '1367',
                qty: 1,
              })
            );
          }
          if (item === 'electroType2') {
            dispatch(
              setOrder({
                id: '1548',
                qty: 1,
              })
            );
          }
        });
        el.arrRemoteControl.forEach((item) => {
          if (item === 'remoteControlType1') {
            dispatch(
              setOrder({
                id: '1292',
                qty: 1,
              })
            );
          }
          if (item === 'remoteControlType2') {
            dispatch(
              setOrder({
                id: '1292',
                qty: 1,
              })
            );
          }
          if (item === 'remoteControlType3') {
            dispatch(
              setOrder({
                id: '1293',
                qty: 1,
              })
            );
          }
        });
      });
      setDeliveryFlag((prev) => !prev);
    });

    (async () => {
      if (!profile) return;
      setDeliveryData(await delivery());
    })();
  }, [profile, activeStep, typeModel]);

  const getIHours = useCallback(
    (id) => {
      const hours = productPrice?.find((el) => el.ProductNo === id);
      if (hours) {
        return hours.InstallationHours;
      }
      return 0;
    },
    [productPrice]
  );

  useEffect(() => {
    let totalInstallationHours = 0;
    order.forEach((item) => {
      totalInstallationHours += getIHours(item.id) * item.qty;
    });

    order.forEach((item) => {
      if (item.id === '1551') {
        dispatch(
          setOrder({
            id: '1551',
            qty: 0,
            Variables: [
              {
                VariableName: 'DistanzKMTGzuKD',
                VariableValue: deliveryData.distance / 1000,
              },
              {
                VariableName: 'FahrzeitStd',
                VariableValue: deliveryData.duration / 3600,
              },
              {
                VariableName: 'AufmassDauerStd',
                VariableValue: 1,
              },
            ],
          })
        );
      }

      if (item.id === '1544') {
        dispatch(
          setOrder({
            id: '1544',
            qty: 0,
            Variables: [
              {
                VariableName: 'MontageStundenAnzahl',
                VariableValue: totalInstallationHours,
              },
              {
                VariableName: 'DistanzKMTGzuKD',
                VariableValue: deliveryData.distance / 1000,
              },
              {
                VariableName: 'FahrzeitStd',
                VariableValue: deliveryData.duration / 3600,
              },
            ],
          })
        );
      }
    });
  }, [productPrice, deliveryFlag, activeStep, typeModel]);

  const format = (str) => {
    if (!str) return 0;
    const res = str.replace(/(\d)(?=(\d{3})+(\D|$))/g, `$1'`);
    return res;
  };
  const getPrice = useCallback(
    (
      id,
      qty,
      side,
      colsectionWindow,
      colsectionLammelen,
      sectionLenght,
      squar
    ) => {
      const price = productPrice?.find(
        (el) =>
          el.ProductNo === id &&
          el.side === side &&
          (el.section === colsectionWindow ||
            el.section === colsectionLammelen) &&
          el.sectionLenght === sectionLenght &&
          el.squar === squar
      );

      if (price) {
        const formatPrice = format((price.PriceSales * qty).toFixed(0));
        return `${formatPrice}.-`;
      }
      return `${0}.-`;
    },
    [productPrice, activeStep, typeModel]
  );
  const getName = useCallback(
    (id) => {
      const price = productPrice?.find((el) => el.ProductNo === id);
      if (price) {
        return price.TitleSales;
      }
      return id;
    },
    [productPrice, activeStep, typeModel]
  );
  const getPHours = useCallback(
    (id) => {
      const hours = productPrice?.find((el) => el.ProductNo === id);
      if (hours) {
        return hours.PlanningHours;
      }
      return 0;
    },
    [productPrice, activeStep, typeModel]
  );
  const total = useMemo(
    () =>
      order.reduce((acc, item) => {
        const price = productPrice?.find((el) => el.ProductNo === item.id);
        if (price) {
          const buffer =
            (+acc + price.PriceSales * item.qty)?.toFixed(0) || acc;

          return buffer;
        }
        return acc;
      }, 0),
    [productPrice, order, activeStep, typeModel]
  );

  const [addQuote] = useAddQuoteMutation();
  // const [addQuoteWithoutAuth] = useAddQuoteWithoutAuthMutation();
  const [addProject] = useAddProjectMutation();
  const [addProjectWithoutAuth] = useAddProjectWithoutAuthMutation();
  const [updateProject] = useUpdateProjectMutation();

  const { number, refresh } = useGenerateNumber({
    skip: !profile,
  });

  useEffect(() => {
    if (!source) return;
    source?.addEventListener('check-side', (data) => {
      setSideWall(data.wall);
    });
  }, [source]);

  // const handleShowIframeModal = () => {
  //   dispatch(setShowIframeModal(true));
  // };

  const sendProductWithoutAuth = async (
    screenScene,
    screenSceneTop,
    contact
  ) => {
    try {
      const config = source.funcSaveProject();

      const { data } = await addProjectWithoutAuth(config);
      dispatch(setSelectedProject(data));

      // await addQuoteWithoutAuth({
      //   innerprice: document.getElementById('accordion-item-price').innerHTML,
      //   comment: '',
      //   projectName: bufferData.name,
      //   project: bufferData._id,
      // });

      // refresh();
      dispatch(setSelectedProject(null));

      await UserService.sendOrder({
        screenScene,
        screenSceneTop,
        profile: contact,
        innerprice: document.getElementById('accordion-item-price').innerHTML,
        comment,
      });
      dispatch(setComment(''));
    } catch (err) {
      console.error(err);
    }
  };

  const sendProduct = async (screenScene, screenSceneTop, contact) => {
    try {
      const config = source.funcSaveProject();
      let bufferData = null;
      if (!selectedProject) {
        const { data } = await addProject(config);
        dispatch(setSelectedProject(data));
        bufferData = data;
      } else {
        config._id = selectedProject._id;
        updateProject(config);
      }
      await addQuote({
        innerprice: document.getElementById('accordion-item-price').innerHTML,
        comment: '',
        number,
        projectName: selectedProject ? selectedProject.name : bufferData.name,
        project: selectedProject ? selectedProject._id : bufferData._id,
      });
      refresh();

      dispatch(setSelectedProject(null));
      await UserService.sendOrder({
        screenScene,
        screenSceneTop,
        profile: contact,
        innerprice: document.getElementById('accordion-item-price').innerHTML,
        comment,
      });
      dispatch(setComment(''));
    } catch (err) {
      console.error(err);
    }
  };
  const sendMessage = async () => {
    if (!profile && !profile2) {
      dispatch(setShowLoginModal(true));
    } else {
      // handleShowIframeModal();
      window.open('https://www.thermogreen.ch/danke/', '_blank');
      setTimeout(async () => {
        const screen = await source.funcScreenScene();
        if (profile) {
          sendProduct(screen.screenScene, screen.screenSceneTop, profile);
        }
        if (profile2 && !profile) {
          sendProductWithoutAuth(
            screen.screenScene,
            screen.screenSceneTop,
            profile2
          );
        }
      }, 500);
    }
  };

  const groupPrice = () => {
    if (!order) return;
    let totalSort1 = 0;
    let totalSort2 = 0;
    let totalSort3 = 0;
    let totalSort4 = 0;
    let totalSort5 = 0;
    let totalSort6 = 0;
    let totalSort7 = 0;
    let totalSort8 = 0;
    let totalSort9 = 0;
    let totalSort10 = 0;
    let totalSort11 = 0;
    let totalSort12 = 0;
    let totalSort13 = 0;
    let totalSort14 = 0;
    let totalSort15 = 0;
    let totalSort16 = 0;
    let totalSort17 = 0;

    let pricePlanung = 0;
    let priceMontage = 0;
    let squareRoofGlass = 0;

    order?.forEach((item) => {
      if (item.id === '1005') {
        priceMontage = Number(getPrice(item.id, item.qty).replace(/\D/g, ''));
      }
      if (item.id === '1008') {
        pricePlanung = Number(getPrice(item.id, item.qty).replace(/\D/g, ''));
      }
      if (item.id === '1001') {
        squareRoofGlass =
          (item.Variables[0].VariableValue * item.Variables[1].VariableValue) /
          10000;
      }

      setTitle(priceMontage + pricePlanung);

      if (item.id === '1061') {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        totalSort1 =
          totalSort1 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (item.id === '1064') {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        totalSort1 =
          totalSort1 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (item.id === '1001' || item.id === '1055') {
        if (item.id === '1055') {
          const phouse = getPHours(item.id);
          const ihouse = getIHours(item.id);

          const priceItem = Number(
            getPrice(item.id, squareRoofGlass).replace(/\D/g, '')
          );
          totalSort2 =
            totalSort2 +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty;
        } else {
          const phouse = getPHours(item.id);
          const ihouse = getIHours(item.id);

          const priceItem = Number(
            getPrice(item.id, item.qty).replace(/\D/g, '')
          );
          totalSort2 =
            totalSort2 +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty;
        }
      }

      if (item.id === '1036' || item.id === '1477' || item.id === '1037') {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        totalSort3 =
          totalSort3 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (
        item.side === 'sideA' &&
        (item.id === '1059' ||
          item.id === '1117' ||
          item.id === '1096' ||
          item.id === '1546')
      ) {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            undefined,
            item.section,
            item.sectionLenght
          ).replace(/\D/g, '')
        );
        totalSort4 =
          totalSort4 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (
        item.side === 'sideB' &&
        (item.id === '1059' ||
          item.id === '1117' ||
          item.id === '1096' ||
          item.id === '1546')
      ) {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            undefined,
            item.section,
            item.sectionLenght
          ).replace(/\D/g, '')
        );
        totalSort5 =
          totalSort5 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (
        item.side === 'sideC' &&
        (item.id === '1059' ||
          item.id === '1117' ||
          item.id === '1096' ||
          item.id === '1546')
      ) {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            undefined,
            item.section,
            item.sectionLenght
          ).replace(/\D/g, '')
        );
        totalSort6 =
          totalSort6 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (
        item.side === 'sideD' &&
        (item.id === '1059' ||
          item.id === '1117' ||
          item.id === '1096' ||
          item.id === '1546')
      ) {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            undefined,
            item.section,
            item.sectionLenght
          ).replace(/\D/g, '')
        );
        totalSort7 =
          totalSort7 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (
        item.side === 'sideA' &&
        (item.id === '1553' ||
          item.id === '1025' ||
          item.id === '1554' ||
          item.id === '1134' ||
          item.id === '1135' ||
          item.id === '1136' ||
          item.id === '1137' ||
          item.id === '1045' ||
          item.id === '1049' ||
          item.id === '1048' ||
          item.id === '1040' ||
          item.id === '1083' ||
          item.id === '1081' ||
          item.id === '1087' ||
          item.id === '1084' ||
          item.id === '1085' ||
          item.id === '1041' ||
          item.id === '1042' ||
          item.id === '1058')
      ) {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        let priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            item.section,
            undefined,
            undefined,
            item.squar
          ).replace(/\D/g, '')
        );

        if (item.squar) {
          priceItem *= item.squar;
        }

        totalSort8 =
          totalSort8 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (
        item.side === 'sideB' &&
        (item.id === '1553' ||
          item.id === '1025' ||
          item.id === '1554' ||
          item.id === '1134' ||
          item.id === '1135' ||
          item.id === '1136' ||
          item.id === '1137' ||
          item.id === '1045' ||
          item.id === '1049' ||
          item.id === '1048' ||
          item.id === '1040' ||
          item.id === '1083' ||
          item.id === '1081' ||
          item.id === '1087' ||
          item.id === '1084' ||
          item.id === '1085' ||
          item.id === '1041' ||
          item.id === '1042' ||
          item.id === '1058')
      ) {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        let priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            item.section,
            undefined,
            undefined,
            item.squar
          ).replace(/\D/g, '')
        );

        if (item.squar) {
          priceItem *= item.squar;
        }

        totalSort9 =
          totalSort9 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (
        item.side === 'sideC' &&
        (item.id === '1553' ||
          item.id === '1025' ||
          item.id === '1554' ||
          item.id === '1134' ||
          item.id === '1135' ||
          item.id === '1136' ||
          item.id === '1137' ||
          item.id === '1045' ||
          item.id === '1049' ||
          item.id === '1048' ||
          item.id === '1040' ||
          item.id === '1083' ||
          item.id === '1081' ||
          item.id === '1087' ||
          item.id === '1084' ||
          item.id === '1085' ||
          item.id === '1041' ||
          item.id === '1042' ||
          item.id === '1058')
      ) {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        let priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            item.section,
            undefined,
            undefined,
            item.squar
          ).replace(/\D/g, '')
        );

        if (item.squar) {
          priceItem *= item.squar;
        }

        totalSort10 =
          totalSort10 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (
        item.side === 'sideD' &&
        (item.id === '1553' ||
          item.id === '1025' ||
          item.id === '1554' ||
          item.id === '1134' ||
          item.id === '1135' ||
          item.id === '1136' ||
          item.id === '1137' ||
          item.id === '1045' ||
          item.id === '1049' ||
          item.id === '1048' ||
          item.id === '1040' ||
          item.id === '1083' ||
          item.id === '1081' ||
          item.id === '1087' ||
          item.id === '1084' ||
          item.id === '1085' ||
          item.id === '1041' ||
          item.id === '1042' ||
          item.id === '1058')
      ) {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        let priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            item.section,
            undefined,
            undefined,
            item.squar
          ).replace(/\D/g, '')
        );

        if (item.squar) {
          priceItem *= item.squar;
        }
        totalSort11 =
          totalSort11 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (item.id === '1547' || item.id === '1250') {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        totalSort12 =
          totalSort12 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (
        item.id === '1180' ||
        item.id === '1165' ||
        item.id === '1184' ||
        item.id === '1321' ||
        item.id === '1099' ||
        item.id === '1245' ||
        item.id === '1291' ||
        item.id === '1292' ||
        item.id === '1293'
      ) {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        totalSort13 =
          totalSort13 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (item.id === '1367' || item.id === '1548') {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        totalSort14 =
          totalSort14 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (item.id === '1090') {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        totalSort15 =
          totalSort15 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
      if (item.id === '1551' || item.id === '1544') {
        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        totalSort16 += priceItem;
      }
      if (item.id === '1094' || item.id === '1097') {
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        totalSort17 =
          totalSort17 +
          priceItem +
          pricePlanung * phouse * item.qty +
          priceMontage * ihouse * item.qty;
      }
    });

    const totalprice =
      totalSort1 +
      totalSort2 +
      totalSort3 +
      totalSort4 +
      totalSort5 +
      totalSort6 +
      totalSort7 +
      totalSort8 +
      totalSort9 +
      totalSort10 +
      totalSort11 +
      totalSort12 +
      totalSort13 +
      totalSort14 +
      totalSort15 +
      totalSort16 +
      totalSort17;

    setTotalVal(format(String(Math.round(totalprice))));
  };

  useEffect(() => {
    groupPrice();
    setPriceUpdate(true);
  }, [order, productPrice, typeModel, activeStep]);

  const renderStep = () => {
    switch (activeStep) {
      case 1:
        return <Step1 totalVal={totalVal} isLoading={isLoading} />;
      case 2:
        return <Step2 totalVal={totalVal} isLoading={isLoading} />;
      case 3:
        return <Step3 totalVal={totalVal} isLoading={isLoading} />;
      case 4:
        return <Step4 totalVal={totalVal} isLoading={isLoading} />;
      case 5:
        return <Step5 totalVal={totalVal} isLoading={isLoading} />;
      case 6:
        return (
          <Step6
            totalVal={totalVal}
            sendMessage={sendMessage}
            getName={getName}
            getPrice={getPrice}
            getPHours={getPHours}
            getIHours={getIHours}
            total={format(total)}
            isLoading={isLoading}
            setPriceUpdate={setPriceUpdate}
            order={order}
          />
        );
      default:
        return <Step1 />;
    }
  };

  return (
    <div
      className={
        activeStep === 1 || activeStep === 2 || activeStep === 3
          ? 'sidePanel'
          : 'sidePanel full'
      }
    >
      {renderStep()}
    </div>
  );
};

export default SidePanel;
