import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/ui/Button';
// import { setOrder } from '../../../store/reducers/Project/ProjectSlice';
import { ReactComponent as Info } from '../../../assets/info.svg';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import { setShowVerglasungModal } from '../../../store/reducers/User/UserSlice';
import './style.scss';

const VerglasungModal = () => {
  const dispatch = useDispatch();
  const { showVerglasungModal } = useSelector((state) => state.user);
  const { source } = useSelector((state) => state.project);

  const handleClose = () => dispatch(setShowVerglasungModal(false));

  const [value, setValue] = useState('1');

  const [valueMax, setValueMax] = useState(null);
  const [valueMin, setValueMin] = useState(null);

  const [state, setState] = useState(null);

  useEffect(() => {
    if (!source) return;
    source?.addEventListener('model-size', (data) => {
      setState({
        typeModel: data.typeModel,
        w: +data.size.w,
        d: +data.size.d,
        h1: +data.size.h1,
        h2: +data.size.h2,
        side: data.side,
      });
      setValue(String(+data.sectionWindow));
    });
  }, [source]);

  const defaultSectionswindowType1 = (size, side) => {
    if (
      (side === 'sideA' || side === 'sideC') &&
      state.typeModel === 'louver'
    ) {
      size -= 200;
    }
    if (
      (side === 'sideB' || side === 'sideD') &&
      state.typeModel === 'louver'
    ) {
      size -= 400;
    }

    if ((side === 'sideA' || side === 'sideC') && state.typeModel === 'glass') {
      size -= 135;
    }
    if ((side === 'sideB' || side === 'sideD') && state.typeModel === 'glass') {
      size -= 200;
    }

    if (state.typeModel === 'louver' && size > 7000) {
      size /= 2;
    }

    if (state.typeModel === 'glass' && size > 7000) {
      size /= 2;
    }

    switch (true) {
      case size >= 0 && size <= 999:
        setValueMin(1);
        setValueMax(2);
        break;
      case size >= 1000 && size <= 1999:
        setValueMin(1);
        setValueMax(3);
        break;
      case size >= 2000 && size <= 2999:
        setValueMin(2);
        setValueMax(4);
        break;
      case size >= 3000 && size <= 3999:
        setValueMin(3);
        setValueMax(5);
        break;
      case size >= 4000 && size <= 4999:
        setValueMin(4);
        setValueMax(6);
        break;
      case size >= 5000 && size <= 5999:
        setValueMin(5);
        setValueMax(7);
        break;
      case size >= 6000 && size <= 6999:
        setValueMin(6);
        setValueMax(8);
        break;
      case size >= 7000 && size <= 7999:
        setValueMin(7);
        setValueMax(9);
        break;
      case size >= 8000 && size <= 9000:
        setValueMin(8);
        setValueMax(10);
        break;
      default:
        break;
    }
  };

  const defaultSectionswindowType2 = (size, side) => {
    if (
      (side === 'sideA' || side === 'sideC') &&
      state.typeModel === 'louver'
    ) {
      size -= 200;
    }
    if (
      (side === 'sideB' || side === 'sideD') &&
      state.typeModel === 'louver'
    ) {
      size -= 400;
    }

    if ((side === 'sideA' || side === 'sideC') && state.typeModel === 'glass') {
      size -= 135;
    }
    if ((side === 'sideB' || side === 'sideD') && state.typeModel === 'glass') {
      size -= 200;
    }
    if (state.typeModel === 'louver' && size > 6980) {
      size /= 2;
    }
    if (state.typeModel === 'glass' && size > 7500) {
      size /= 2;
    }

    if (state.typeModel === 'louver') {
      const arrSection = [];

      if (size >= 1000 && size <= 2800) {
        arrSection.push(2);
      }
      if (size >= 1800 && size <= 4200) {
        arrSection.push(3);
      }
      if (size >= 2600 && size <= 5600) {
        arrSection.push(4);
      }
      if (size >= 3200 && size <= 6980) {
        arrSection.push(5);
      }
      setValueMin(Math.min(...arrSection));
      setValueMax(Math.max(...arrSection));
    }

    if (state.typeModel === 'glass') {
      const arrSection = [];

      if (size >= 1000 && size <= 4500) {
        arrSection.push(2);
      }
      if (size >= 1750 && size <= 5500) {
        arrSection.push(3);
      }
      if (size >= 3000 && size <= 6500) {
        arrSection.push(4);
      }
      if (size >= 4000 && size <= 7500) {
        arrSection.push(5);
      }
      setValueMin(Math.min(...arrSection));
      setValueMax(Math.max(...arrSection));
    }
  };

  useEffect(() => {
    if (!state) return;
    if (showVerglasungModal === 'windowType1') {
      if (state.side === 'sideB' || state.side === 'sideD') {
        defaultSectionswindowType1(state.w, state.side);
      }
      if (state.side === 'sideA' || state.side === 'sideC') {
        defaultSectionswindowType1(state.d, state.side);
      }
    }
    if (showVerglasungModal === 'windowType2') {
      if (state.side === 'sideB' || state.side === 'sideD') {
        defaultSectionswindowType2(state.w, state.side);
      }
      if (state.side === 'sideA' || state.side === 'sideC') {
        defaultSectionswindowType2(state.d, state.side);
      }
    }
  }, [state]);

  const window = () => {
    const data = source.funcToggleWindow(
      undefined,
      true,
      showVerglasungModal,
      value
    );

    if (data === undefined) {
      toast.warn('es ist verboten');
      return;
    }
    handleClose();
  };

  return (
    <ModalWrapper
      show={showVerglasungModal}
      close={handleClose}
      customClass="wrapper-verglasung-options"
    >
      <ModalHeader title="Verglasung options" close={handleClose} />
      <div className="container-verglasung-options">
        <div className="section-verglasung-options">
          <div className="title-section-verglasung-options">
            Glas-Elemente <Info />
          </div>
          <div className="input-section-verglasung-options">
            <input
              type="range"
              min={valueMin}
              max={valueMax}
              onChange={(e) => setValue(e.target.value)}
              style={{
                backgroundSize: `${
                  ((value - valueMin) / (valueMax - valueMin)) * 100
                }% 100%`,

                margin: 0,
              }}
              value={value}
            />
            <div className="titles-input-verglasung-options">
              {Array(valueMax + 1 - valueMin)
                .fill()
                .map((_, i) => i + valueMin)
                .map((item) => (
                  <span
                    key={item}
                    className={
                      item === +value
                        ? 'input-verglasung-options-value-g active'
                        : 'input-verglasung-options-value-g'
                    }
                  >
                    {item}
                  </span>
                ))}
            </div>
          </div>
        </div>
        {/* <div className="section-verglasung-options">
          <div className="title-section-verglasung-options">
            Schiene <Info />
          </div>
          <div className="content-section-verglasung-options">
            <div className="verglasung-options-img">
              <Button className="verglasung-options-content" onClick={() => {}}>
                <img src="" alt="" />
              </Button>
              <div className="verglasung-options-title">Stufe</div>
            </div>
            <div className="verglasung-options-img">
              <Button className="verglasung-options-content" onClick={() => {}}>
                <img src="" alt="" />
              </Button>
              <div className="verglasung-options-title">Stufenlos</div>
            </div>
          </div>
        </div> */}

        {/* <div className="section-verglasung-options">
          <div className="title-section-verglasung-options">
            Verriegelung <Info />
          </div>
          <div className="content-section-verglasung-options">
            <div className="verglasung-options-img">
              <Button className="verglasung-options-content" onClick={() => {}}>
                <img src="" alt="" />
              </Button>
              <div className="verglasung-options-title">Standard</div>
            </div>
          </div>
        </div> */}
      </div>
      <Button
        className="btn-verglasung-options"
        onClick={() => {
          window();
        }}
      >
        OK
      </Button>
    </ModalWrapper>
  );
};

export default VerglasungModal;
