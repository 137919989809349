export default class SizeElCommand {
  constructor(app, object, data, funcUpdatePrice) {
    this.app = app;
    this.object = object;
    this.windowsSections = data.windowsSections;
    this.lamellenSections = data.lamellenSections;
    this.lamellenWidths = data.lamellenWidths;
    this.prevSize = data.prev;
    this.nextSize = data.next;
    this.funcUpdatePrice = funcUpdatePrice;

    //window
    this.windows = {};
    this.app.elements.forEach((element) => {
      this.windows[element.uuid] = element.arrWindow;
    });

    // zip
    this.zips = {};
    this.app.elements.forEach((element) => {
      this.zips[element.uuid] = element.arrZip;
    });

    // lamellen
    this.lamellens = {};
    this.app.elements.forEach((element) => {
      this.lamellens[element.uuid] = element.arrLamellen;
    });
  }

  execute() {
    this.app.funcEditElPerformance(
      this.nextSize[0],
      this.nextSize[1],
      this.nextSize[2],
      this.nextSize[3],
      this.object
    );
    this.funcUpdatePrice();
  }

  undo() {
    this.app.funcEditElPerformance(
      this.prevSize[0],
      this.prevSize[1],
      this.prevSize[2],
      this.prevSize[3],
      this.object,
      this.windows,
      this.zips,
      this.lamellens,
      this.windowsSections,
      this.lamellenSections,
      this.lamellenWidths
    );
    this.funcUpdatePrice();
  }
}
