
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CustomSimpleBar from 'components/CustomSimpleBar';
import { useDispatch, useSelector } from 'react-redux';
import Range from '../../../components/ui/Range';
import { useGetProductInfoQuery } from '../../../services/projects.service';
import SidePanelFooter from '../SidePanelFooter';
import {
  decrementStep,
  incrementStep,
} from '../../../store/reducers/User/UserSlice';
import {
  savePrevSizes,
  setElementRes,
  updateSideFilled,
} from '../../../store/reducers/Project/ProjectSlice';

import './style.scss';



const Step2 = ({ totalVal, isLoading }) => {
  const dispatch = useDispatch();
  const { source, typeModel, prevSizes } = useSelector(
    (state) => state.project
  );
  const activeStep = useSelector((state) => state.user.activeStep);
  const { data: products } = useGetProductInfoQuery({
    products: ['1061', '1001', '1064'],
  });
  const [warning, setWarning] = useState(false);
  const [sizeWidth, setSizeWidth] = useState(0);
  const [sizeDepth, setSizeDepth] = useState(0);
  const [sizeHeight1, setSizeHeight1] = useState(0);
  const [sizeHeight2, setSizeHeight2] = useState(0);
  const [angle, setAngle] = useState(0);
  const [hasChanged, setHasChanged] = useState(false);
  const [arr, setArr] = useState([]);
  const [arrWarn, setArrWarn] = useState([]);
  const [changeFlag] = useState(true);
  const prevTypeModelRef = useRef(typeModel);

  const prevSizeWidthRef = useRef(source.globalWidth);
  const prevSizeDepthRef = useRef(source.globalDepth);
  const prevSizeHeight1Ref = useRef(source.globalHeight1);
  const prevSizeHeight2Ref = useRef(source.globalHeight2);

  const updateSizeWidth = (newWidth) => {
    prevSizeWidthRef.current = sizeWidth;
    setSizeWidth(newWidth);
  };

  const updateSizeDepth = (newDepth) => {
    prevSizeDepthRef.current = sizeDepth;
    setSizeDepth(newDepth);
  };

  const updateSizeHeight1 = (newHeight1) => {
    prevSizeHeight1Ref.current = sizeHeight1;
    setSizeHeight1(newHeight1);
  };

  const updateSizeHeight2 = (newHeight2) => {
    prevSizeHeight2Ref.current = sizeHeight2;
    setSizeHeight2(newHeight2);
  };

  const getDescription = (item) => {
    console.log(item, 'getDescription');

    if (typeModel === 'louver' || typeModel === 'louver2') {
      // Для louver
      if (['sideA', 'sideD', 'sideC', 'sideB'].includes(item.side)) {
        switch (item.type) {
          case 'windowType1':
            return 'S-Design Rechteck-Festfenster Seitenglas: ESG 10mm';
          case 'windowType2':
            return 'S-Design Ganz Glas Schiebeanlage';
          case 'zipType2':
            return 'Senkrechtbeschattung zu SKL50/100';
          case 'zipType1':
            return 'Senkrechtbeschattung zu SKL50/100';
          case 'lamellenType1':
            return 'SKL Schiebeladen mit Laufschiene';
          default:
            return '';
        }
      }
    } else if (typeModel === 'glass') {
      // Для Glass
      if (['sideA', 'sideC', 'sideB'].includes(item.side)) {
        switch (item.type) {
          case 'windowType1':
            return 'S-Design Festfenster Seitenglas: ESG 8mm';
          case 'windowType2':
            return 'S-Design Ganz Glas Schiebeanlage';
          case 'zipType2':
            return 'Senkrechtbeschattung S-Design WS500R';
          case 'zipType1':
            return 'Senkrechtbeschattung S-Design WS500E';
          default:
            return '';
        }
      } else if (item.side === 'sideRoof') {
        switch (item.type) {
          case 'zipType3':
            return 'Dachbeschattung S-Design WD600E';
          case 'zipType1':
            return 'Dachbeschattung S-Design WD600E';
          case 'zipType2':
            return 'Dachbeschattung S-Design WD600R';
          default:
            return '';
        }
      }
    }
    return '';
  };

  useEffect(() => {
    if (Array.isArray(arr)) {
      const newWarnArr = arr.map(getDescription);
      setArrWarn(newWarnArr);
    }
  }, [arr]);

  const handleChangeSize = () => {
    setArrWarn([]);
    source?.clearArrForDell();
  };

  useEffect(() => {
    const a = sizeHeight2 - sizeHeight1;
    const b = sizeDepth; // -95
    const c = Math.sqrt(a * a + b * b);
    const alpha = Math.asin(a / c);

    const res = (alpha * 180) / Math.PI;
    const resNumber = Math.round(res * 10) / 10;

    setAngle(resNumber);
  }, [sizeDepth, sizeHeight1, sizeHeight2]);

  useEffect(() => {
    setSizeWidth(source.globalWidth);
    setSizeDepth(source.globalDepth);
    setSizeHeight1(source.globalHeight1);
    setSizeHeight2(source.globalHeight2);
  }, [source, activeStep, typeModel]);

  useEffect(() => {
    if (prevTypeModelRef.current !== typeModel) {
      if (typeModel === 'louver' || typeModel === 'louver2') {
        updateSizeWidth(5000);
        updateSizeDepth(3000);
        updateSizeHeight1(2100);
        updateSizeHeight2(2500);
      } else if (typeModel === 'glass') {
        updateSizeWidth(5000);
        updateSizeDepth(3000);
        updateSizeHeight1(2100);
        updateSizeHeight2(2500);
      }
      prevTypeModelRef.current = typeModel;
    }
  }, [typeModel]);

  // useEffect(() => {
  //   if (!source) return;
  //   source?.addEventListener('edit-open', (data) => {
  //     setSizeWidth(data.size.w * 1000);
  //     setSizeDepth(data.size.d * 1000);
  //     setSizeHeight1(data.size.h1 * 1000);
  //     setSizeHeight2(data.size.h2 * 1000);
  //   });
  // }, [source]);

  const sizeLouver = useMemo(() => {
    if (!products) {
      return {
        width: {
          min: 0,
          max: 0,
        },
        depth: {
          min: 0,
          max: 0,
        },
        height1: {
          min: 0,
          max: 0,
        },
      };
    }

    const product = products.find((item) => item.ProductNo === '1061');
    const width = product.WS_ProductVariables.find(
      (item) => item.Name === 'Breite'
    );
    const depth = product.WS_ProductVariables.find(
      (item) => item.Name === 'Tiefe'
    );
    const height1 = product.WS_ProductVariables.find(
      (item) => item.Name === 'HoeheOKT'
    );
    return {
      width: {
        min: width.MinValue * 10,
        max: width.MaxValue * 10,
      },
      depth: {
        min: depth.MinValue * 10,
        max: depth.MaxValue * 10,
      },
      height1: {
        min: height1.MinValue * 10,
        max: height1.MaxValue * 10,
      },
    };
  }, [products, activeStep, typeModel]);


  const sizeLouver2 = useMemo(() => {
    if (!products) {
      return {
        width: {
          min: 0,
          max: 0,
        },
        depth: {
          min: 0,
          max: 0,
        },
        height1: {
          min: 0,
          max: 0,
        },
      };
    }

    const product = products.find((item) => item.ProductNo === '1064');
    const width = product.WS_ProductVariables.find(
      (item) => item.Name === 'Breite'
    );
    const depth = product.WS_ProductVariables.find(
      (item) => item.Name === 'Tiefe'
    );
    const height1 = product.WS_ProductVariables.find(
      (item) => item.Name === 'HoeheOKT'
    );
    return {
      width: {
        min: width.MinValue * 10,
        max: width.MaxValue * 10,
      },
      depth: {
        min: depth.MinValue * 10,
        max: depth.MaxValue * 10,
      },
      height1: {
        min: height1.MinValue * 10,
        max: height1.MaxValue * 10,
      },
    };
  }, [products, activeStep, typeModel]);

  const sizeGlass = useMemo(() => {
    if (!products) {
      return {
        width: {
          min: 0,
          max: 0,
        },
        depth: {
          min: 0,
          max: 0,
        },
        height1: {
          min: 0,
          max: 0,
        },
      };
    }

    const product = products.find((item) => item.ProductNo === '1001');
    const width = product.WS_ProductVariables.find(
      (item) => item.Name === 'Breite'
    );
    const depth = product.WS_ProductVariables.find(
      (item) => item.Name === 'Tiefe'
    );
    const height1 = product.WS_ProductVariables.find(
      (item) => item.Name === 'HoeheUKT'
    );
    const height2 = product.WS_ProductVariables.find(
      (item) => item.Name === 'HoeheOKW'
    );
    return {
      width: {
        min: width.MinValue * 10,
        max: width.MaxValue * 10,
      },
      depth: {
        min: depth.MinValue * 10,
        max: depth.MaxValue * 10,
      },
      height1: {
        min: height1.MinValue * 10,
        max: height1.MaxValue * 10,
      },
      height2: {
        min: height2.MinValue * 10,
        max: height2.MaxValue * 10,
      },
    };
  }, [products, activeStep, typeModel]);

  const editSize = () => {
    if (typeModel === 'louver') {
      if (!source || !source.funcEditEl) {
        console.error(
          'source is not initialized or funcEditEl is not a function'
        );
        return;
      }
      (async () => {
        try {
          const result = await source.funcEditEl(
            sizeWidth < sizeLouver.width.min ? sizeLouver.width.min : sizeWidth,
            sizeDepth < sizeLouver.depth.min ? sizeLouver.depth.min : sizeDepth,
            sizeHeight1 < sizeLouver.height1.min
              ? sizeLouver.height1.min
              : sizeHeight1,
            sizeHeight2,
            changeFlag
          );
          if (result === undefined) {
            const currentSizes = {
              width: sizeWidth,
              depth: sizeDepth,
              height1: sizeHeight1,
              height2: sizeHeight2,
            };
            dispatch(savePrevSizes(currentSizes));
          }
          setArr(result);
        } catch (error) {
          console.log('Ошибка выполнения');
          setArr('Ошибка выполнения');
        }
      })();
    }

    if (typeModel === 'louver2') {
      if (!source || !source.funcEditEl) {
        console.error(
          'source is not initialized or funcEditEl is not a function'
        );
        return;
      }
      (async () => {
        try {
          const result = await source.funcEditEl(
            sizeWidth < sizeLouver2.width.min ? sizeLouver2.width.min : sizeWidth,
            sizeDepth < sizeLouver2.depth.min ? sizeLouver2.depth.min : sizeDepth,
            sizeHeight1 < sizeLouver2.height1.min
              ? sizeLouver2.height1.min
              : sizeHeight1,
            sizeHeight2,
            changeFlag
          );
          if (result === undefined) {
            const currentSizes = {
              width: sizeWidth,
              depth: sizeDepth,
              height1: sizeHeight1,
              height2: sizeHeight2,
            };
            dispatch(savePrevSizes(currentSizes));
          }
          setArr(result);
        } catch (error) {
          console.log('Ошибка выполнения');
          setArr('Ошибка выполнения');
        }
      })();
    }


    if (typeModel === 'glass') {
      if (!source || !source.funcEditEl) {
        console.error(
          'source is not initialized or funcEditEl is not a function'
        );
        return;
      }
      (async () => {
        try {
          const result = await source.funcEditEl(
            sizeWidth < sizeGlass.width.min ? sizeGlass.width.min : sizeWidth,
            sizeDepth < sizeGlass.depth.min ? sizeGlass.depth.min : sizeDepth,
            sizeHeight1 < sizeGlass.height1.min
              ? sizeGlass.height1.min
              : sizeHeight1,
            sizeHeight2 < sizeGlass.height2.min
              ? sizeGlass.height2.min
              : sizeHeight2,
            changeFlag
          );
          if (result === undefined) {
            const currentSizes = {
              width: sizeWidth,
              depth: sizeDepth,
              height1: sizeHeight1,
              height2: sizeHeight2,
            };
            dispatch(savePrevSizes(currentSizes));
          }
          setArr(result);
        } catch (error) {
          setArr('Ошибка выполнения');
        }
      })();
    }
  };

  useEffect(() => {
    if (typeModel === 'glass' && (angle < 4.5 || angle > 20)) {
      setWarning(true);
    } else {
      setWarning(false);
    }
  }, [
    sizeWidth,
    sizeDepth,
    sizeHeight1,
    sizeHeight2,
    typeModel,
    angle,
    setWarning,
  ]);

  const handleNext = () => {
    if (hasChanged) {
      if (!warning) {
        editSize();
        setHasChanged(false);
      }
    } else if (arrWarn.length > 0) {
      const processedSides = new Set();

      arr.forEach((warnItem) => {
        if (!processedSides.has(warnItem.side)) {
          dispatch(updateSideFilled(warnItem.side, false));
          processedSides.add(warnItem.side);
        }
      });
      if (typeModel === 'louver') {
        source
          .funcEditEl(
            sizeWidth < sizeLouver.width.min ? sizeLouver.width.min : sizeWidth,
            sizeDepth < sizeLouver.depth.min ? sizeLouver.depth.min : sizeDepth,
            sizeHeight1 < sizeLouver.height1.min
              ? sizeLouver.height1.min
              : sizeHeight1,
            sizeHeight2,
            changeFlag
          )
          .then(() => {
            setArrWarn([]);
            dispatch(setElementRes(undefined));
            const prevSizesMod2 = {
              width: sizeWidth,
              depth: sizeDepth,
              height1: sizeHeight1,
              height2: sizeHeight2,
            };
            dispatch(savePrevSizes(prevSizesMod2));
          });
      } else if (typeModel === 'louver2') {
        source
          .funcEditEl(
            sizeWidth < sizeLouver2.width.min ? sizeLouver2.width.min : sizeWidth,
            sizeDepth < sizeLouver2.depth.min ? sizeLouver2.depth.min : sizeDepth,
            sizeHeight1 < sizeLouver2.height1.min
              ? sizeLouver2.height1.min
              : sizeHeight1,
            sizeHeight2,
            changeFlag
          )
          .then(() => {
            setArrWarn([]);
            dispatch(setElementRes(undefined));
            const prevSizesMod2 = {
              width: sizeWidth,
              depth: sizeDepth,
              height1: sizeHeight1,
              height2: sizeHeight2,
            };
            dispatch(savePrevSizes(prevSizesMod2));
          });
      } else if (typeModel === 'glass') {
        source
          .funcEditEl(
            sizeWidth < sizeGlass.width.min ? sizeGlass.width.min : sizeWidth,
            sizeDepth < sizeGlass.depth.min ? sizeGlass.depth.min : sizeDepth,
            sizeHeight1 < sizeGlass.height1.min
              ? sizeGlass.height1.min
              : sizeHeight1,
            sizeHeight2 < sizeGlass.height2.min
              ? sizeGlass.height2.min
              : sizeHeight2,
            changeFlag
          )
          .then(() => {
            setArrWarn([]);
            dispatch(setElementRes(undefined));
            const prevSizesMod2 = {
              width: sizeWidth,
              depth: sizeDepth,
              height1: sizeHeight1,
              height2: sizeHeight2,
            };
            dispatch(savePrevSizes(prevSizesMod2));
          });
      }
    } else {
      dispatch(incrementStep());
      source?.clearArrForDell();
    }
  };

  const handleBack = () => {
    dispatch(decrementStep());
  };

  useEffect(() => {
    if (Object.values(prevSizes).some((value) => value === null)) {
      const prevSizesMod = {
        width: source.globalWidth,
        depth: source.globalDepth,
        height1: source.globalHeight1,
        height2: source.globalHeight2,
      };
      dispatch(savePrevSizes(prevSizesMod));
      return;
    }
    const currentDepth = Number(source.globalDepth);
    const prevDepth = Number(prevSizes.depth);
    const currentHeight1 = Number(source.globalHeight1);
    const prevHeight1 = Number(prevSizes.height1);
    const currentHeight2 = Number(source.globalHeight2);
    const prevHeight2 = Number(prevSizes.height2);
    const currentWidth = Number(source.globalWidth);
    const prevWidth = Number(prevSizes.width);

    if (currentDepth !== prevDepth) {
      setSizeDepth(prevDepth);
    }
    if (currentHeight1 !== prevHeight1) {
      setSizeHeight1(prevHeight1);
    }
    if (currentHeight2 !== prevHeight2) {
      setSizeHeight2(prevHeight2);
    }
    if (currentWidth !== prevWidth) {
      setSizeWidth(prevWidth);
    }
  }, [activeStep]);
  return (
    <>
      <div className="step2">
        <CustomSimpleBar style={{maxHeight: '100%', width: '100%' }}>
          <div className="step2__content">
            <Range
              sizeModel={
                typeModel === 'glass' ? sizeGlass.width :  typeModel === 'louver' ? sizeLouver.width : sizeLouver2.width
              }
              value={sizeWidth}
              setSize={setSizeWidth}
              prevSizeRef={prevSizeWidthRef.current}
              handleChangeSize={handleChangeSize}
              title="Breite"
              setHasChanged={setHasChanged}
            />
            <div className="step2__line" />
            <Range
              sizeModel={
                typeModel === 'glass' ? sizeGlass.depth :  typeModel === 'louver' ? sizeLouver.depth : sizeLouver2.depth
              }
              value={sizeDepth}
              setSize={setSizeDepth}
              prevSizeRef={prevSizeDepthRef.current}
              handleChangeSize={handleChangeSize}
              title="Tiefe"
              setHasChanged={setHasChanged}
            />
            <div className="step2__line" />
            <Range
              sizeModel={
                typeModel === 'glass' ? sizeGlass.height2 :  typeModel === 'louver' ? sizeLouver.height1 : sizeLouver2.height1  
              }
              value={typeModel === 'glass' ? sizeHeight2 : sizeHeight1}
              setSize={typeModel === 'glass' ? setSizeHeight2 : setSizeHeight1}
              prevSizeRef={
                typeModel === 'glass'
                  ? prevSizeHeight2Ref.current
                  : prevSizeHeight1Ref.current
              }
              handleChangeSize={handleChangeSize}
              title={typeModel === 'glass' ? 'Höhe Wandanschluss' : 'Höhe'}
              setHasChanged={setHasChanged}
            />
            {typeModel === 'glass' && (
              <>
                <div className="step2__line" />
                <Range
                  sizeModel={sizeGlass.height1}
                  value={sizeHeight1}
                  setSize={setSizeHeight1}
                  prevSizeRef={prevSizeHeight1Ref.current}
                  handleChangeSize={handleChangeSize}
                  title="Höhe Durchgang"
                  setHasChanged={setHasChanged}
                />
              </>
            )}
            <div className="step2__notif2">
              {typeModel === 'glass' && (angle < 4.5 || angle > 20) ? (
                <div className="step2__notif2-wrap">
                  {angle > 10
                    ? `Die Dachneigung darf nicht weniger sein als 20, aktuell  ${angle}°.`
                    : `Die Dachneigung darf nicht höher sein als 4.5, aktuell ${angle}°.`}
                </div>
              ) : null}
            </div>
            {arrWarn.length > 0 && (
              <div className="step2__notif">
                <div className="step2__notif-main">
                  Folgende Elemente werden gelöscht, da diese nicht in der
                  gewünschten grösse im Konfigurator verfügbar sind.
                </div>
                <div className="step2__notif-secondary">
                  {arrWarn &&
                    arrWarn?.map((item, index) => (
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className="step2__notif-secondary-textWrap"
                      >
                        <div className="step2__notif-secondary-textWrap-dot" />
                        <div className="step2__notif-secondary-textWrap-text">
                          {item}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </CustomSimpleBar>
      </div>

      <SidePanelFooter
        isLoading={isLoading}
        totalVal={totalVal}
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disableBtn={warning === true}
        hasChanged={hasChanged}
        arrWarn={arrWarn}
      />
    </>
  );
};

export default Step2;
