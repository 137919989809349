import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  changePassword,
  getCrmInfo,
  initialization,
  resetPassword,
  sendResetPasswordMail,
  setProfile2,
  signIn,
  signOut,
  signUp,
  updateUser,
} from './ActionCreators';

const initialState = {
  profile: undefined,
  profile2: null,
  productsCost: null,

  showLoginModal: false,
  showInfoModal: false,
  showDeleteModal: false,
  showRegistrationModal: false,
  showResetPasswordModal: false,
  showAccountModal: false,
  showSaveProjectModal: false,
  selectedQuote: null,
  showSelectModalProduct: true,
  showSearchModalProduct: false,
  showVerglasungModal: false,
  showModelSizeModal: false,
  showWarningModalRestart: false,
  showSiteRedirectModal: false,
  showTypeZipModal: false,
  showTypeZipRoofModal: false,
  showLedModal: false,
  showTypeLamellenModal: false,
  showTypeLamellendachModal: false,
  showIframeModal: false,
  isLoading: '',
  expertMode: true,
  activeStep: 1,
  maxReachedStep: 1,
  comment: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setShowIframeModal(state, action) {
      state.showIframeModal = action.payload;
    },
    setShowTypeZipModal(state, action) {
      state.showTypeZipModal = action.payload;
    },
    setShowTypeLamellenModal(state, action) {
      state.showTypeLamellenModal = action.payload;
    },
    setShowTypeLamellendachModal(state, action) {
      state.showTypeLamellendachModal = action.payload;
    },
    setShowTypeZipRoofModal(state, action) {
      state.showTypeZipRoofModal = action.payload;
    },
    setShowLedModal(state, action) {
      state.showLedModal = action.payload;
    },
    setShowSiteRedirectModal(state, action) {
      state.showSiteRedirectModal = action.payload;
    },
    setShowWarningModalRestart(state, action) {
      state.showWarningModalRestart = action.payload;
    },
    setShowModelSizeModal(state, action) {
      state.showModelSizeModal = action.payload;
    },
    setShowVerglasungModal(state, action) {
      state.showVerglasungModal = action.payload;
    },
    setShowSearchModalProduct(state, action) {
      state.showSearchModalProduct = action.payload;
    },
    setShowSelectModalProduct(state, action) {
      state.showSelectModalProduct = action.payload;
    },
    setShowSaveProjectModal(state, action) {
      state.showSaveProjectModal = action.payload;
    },
    setShowRegistrationModal(state, action) {
      state.showRegistrationModal = action.payload;
    },
    setShowResetPasswordModal(state, action) {
      state.showResetPasswordModal = action.payload;
    },
    setShowLoginModal(state, action) {
      state.showLoginModal = action.payload;
    },

    setShowInfoModal(state, action) {
      state.showInfoModal = action.payload;
    },
    setShowDeleteModal(state, action) {
      state.showDeleteModal = action.payload;
    },

    setShowAccountModal(state, action) {
      state.showAccountModal = action.payload;
    },
    setSelectedQuote(state, action) {
      state.selectedQuote = action.payload;
    },
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
    incrementStep(state) {
      if (state.activeStep !== 6) {
        state.activeStep += 1;
        if (state.activeStep > state.maxReachedStep) {
          state.maxReachedStep = state.activeStep;
        }
      }
    },
    decrementStep(state) {
      if (state.activeStep > 1) {
        state.activeStep -= 1;
      }
    },
    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },
    setExpertMode(state, action) {
      state.expertMode = action.payload;
    },

    setComment(state, action) {
      state.comment = action.payload;
    },

    setProfile2(state, action) {
      state.profile2 = action.payload;
    },
  },
  extraReducers: {
    [setProfile2.fulfilled]: (state, action) => {
      state.profile2 = action.payload;
    },
    // initialization
    [initialization.fulfilled.type]: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    [initialization.pending.type]: (state) => {
      state.isLoading = true;
    },
    [initialization.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },
    // signIn
    [signIn.fulfilled.type]: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    [signIn.pending.type]: (state) => {
      state.isLoading = true;
    },
    [signIn.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },
    // signUp
    [signUp.fulfilled.type]: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    [signUp.pending.type]: (state) => {
      state.isLoading = true;
    },
    [signUp.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },
    // resetPassword
    [resetPassword.rejected.type]: (state, action) => {
      toast.error(action.payload);
    },
    // changePassword
    [changePassword.rejected.type]: (state, action) => {
      toast.error(action.payload);
    },
    // sendResetPasswordMail
    [sendResetPasswordMail.rejected.type]: (state, action) => {
      toast.error(action.payload);
    },
    // signOut
    [signOut.fulfilled.type]: (state) => {
      state.profile = null;
      state.isLoading = false;
    },
    [signOut.pending.type]: (state) => {
      state.isLoading = true;
    },
    [signOut.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },
    // update
    [updateUser.fulfilled.type]: (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    [updateUser.pending.type]: (state) => {
      state.isLoading = true;
    },
    [updateUser.rejected.type]: (state, action) => {
      state.isLoading = false;
      toast.error(action.payload);
    },
    // crm
    [getCrmInfo.fulfilled.type]: (state, action) => {
      state.productsCost = action.payload;
    },
    [getCrmInfo.rejected.type]: (state, action) => {
      toast.error(action.payload);
    },
  },
});

const {
  setSelectedQuote,
  setShowSaveProjectModal,
  setShowAccountModal,
  setSelectedProject,
  setShowLoginModal,
  setShowInfoModal,
  setShowDeleteModal,
  setShowSelectModalProduct,
  setShowSearchModalProduct,
  setShowRegistrationModal,
  setShowResetPasswordModal,
  setShowVerglasungModal,
  setShowModelSizeModal,
  setShowWarningModalRestart,
  setShowSiteRedirectModal,
  setShowTypeZipModal,
  setShowTypeZipRoofModal,
  setShowLedModal,
  setShowTypeLamellenModal,
  setShowTypeLamellendachModal,
  setShowIframeModal,
  incrementStep,
  decrementStep,
  setActiveStep,
  setExpertMode,
  setComment,
} = userSlice.actions;

export {
  signIn,
  signUp,
  signOut,
  updateUser,
  initialization,
  setSelectedQuote,
  setShowSaveProjectModal,
  setShowAccountModal,
  setSelectedProject,
  setShowSelectModalProduct,
  setShowSearchModalProduct,
  setShowRegistrationModal,
  setShowResetPasswordModal,
  setShowLoginModal,
  setShowInfoModal,
  setShowDeleteModal,
  setShowVerglasungModal,
  setShowModelSizeModal,
  setShowWarningModalRestart,
  setShowSiteRedirectModal,
  setShowTypeZipModal,
  setShowTypeZipRoofModal,
  setShowLedModal,
  setShowTypeLamellenModal,
  setShowTypeLamellendachModal,
  setShowIframeModal,
  incrementStep,
  decrementStep,
  setActiveStep,
  setExpertMode,
  setComment,
};

export default userSlice.reducer;
