import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { Spinner } from '../../components/Loading';

const Order = ({
  order,
  getName,
  getPrice,
  getPHours,
  getIHours,
  isLoading,
  typeModel,
  setPriceUpdate,
}) => {
  const [sort1, setSort1] = useState([]);
  const [totalSort1, setTotalSort1] = useState(0);
  const [sort2, setSort2] = useState([]);
  const [totalSort2, setTotalSort2] = useState(0);
  const [sort3, setSort3] = useState([]);
  const [totalSort3, setTotalSort3] = useState(0);
  const [sort4, setSort4] = useState([]);
  const [totalSort4, setTotalSort4] = useState(0);
  const [sort5, setSort5] = useState([]);
  const [totalSort5, setTotalSort5] = useState(0);
  const [sort6, setSort6] = useState([]);
  const [totalSort6, setTotalSort6] = useState(0);
  const [sort7, setSort7] = useState([]);
  const [totalSort7, setTotalSort7] = useState(0);
  const [sort8, setSort8] = useState([]);
  const [totalSort8, setTotalSort8] = useState(0);
  const [sort9, setSort9] = useState([]);
  const [totalSort9, setTotalSort9] = useState(0);
  const [sort10, setSort10] = useState([]);
  const [totalSort10, setTotalSort10] = useState(0);
  const [sort11, setSort11] = useState([]);
  const [totalSort11, setTotalSort11] = useState(0);
  const [sort12, setSort12] = useState([]);
  const [totalSort12, setTotalSort12] = useState(0);
  const [sort13, setSort13] = useState([]);
  const [totalSort13, setTotalSort13] = useState(0);
  const [sort14, setSort14] = useState([]);
  const [totalSort14, setTotalSort14] = useState(0);
  const [sort15, setSort15] = useState([]);
  const [totalSort15, setTotalSort15] = useState(0);
  const [sort16, setSort16] = useState([]);
  const [totalSort16, setTotalSort16] = useState(0);
  const [sort17, setSort17] = useState([]);
  const [totalSort17, setTotalSort17] = useState(0);

  const [title, setTitle] = useState(false);
  const expertMode = useSelector((state) => state.user.expertMode);

  const { source } = useSelector((state) => state.project);

  const groupPrice = () => {
    if (!order) return;
    setTotalSort1(0);
    setSort1([]);
    setTotalSort2(0);
    setSort2([]);
    setTotalSort3(0);
    setSort3([]);
    setTotalSort4(0);
    setSort4([]);
    setTotalSort5(0);
    setSort5([]);
    setTotalSort6(0);
    setSort6([]);
    setTotalSort7(0);
    setSort7([]);
    setTotalSort8(0);
    setSort8([]);
    setTotalSort9(0);
    setSort9([]);
    setTotalSort10(0);
    setSort10([]);
    setTotalSort11(0);
    setSort11([]);
    setTotalSort12(0);
    setSort12([]);
    setTotalSort13(0);
    setSort13([]);
    setTotalSort14(0);
    setSort14([]);
    setTotalSort15(0);
    setSort15([]);
    setTotalSort16(0);
    setSort16([]);
    setTotalSort17(0);
    setSort17([]);

    let pricePlanung = 0;
    let priceMontage = 0;
    let squareRoofGlass = 0;

    order?.forEach((item) => {
      if (item.id === '1005') {
        priceMontage = Number(getPrice(item.id, item.qty).replace(/\D/g, ''));
      }
      if (item.id === '1008') {
        pricePlanung = Number(getPrice(item.id, item.qty).replace(/\D/g, ''));
      }
      if (item.id === '1001') {
        squareRoofGlass =
          (item.Variables[0].VariableValue * item.Variables[1].VariableValue) /
          10000;
      }

      setTitle(priceMontage + pricePlanung);

      if (item.id === '1061') {
        setSort1((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        setTotalSort1(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (item.id === '1064') {
        setSort1((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        setTotalSort1(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (item.id === '1001' || item.id === '1055') {
        setSort2((prev) => [...prev, item]);
        if (item.id === '1055') {
          const phouse = getPHours(item.id);
          const ihouse = getIHours(item.id);

          const priceItem = Number(
            getPrice(item.id, squareRoofGlass).replace(/\D/g, '')
          );
          setTotalSort2(
            (prev) =>
              prev +
              priceItem +
              pricePlanung * phouse * item.qty +
              priceMontage * ihouse * item.qty
          );
        } else {
          const phouse = getPHours(item.id);
          const ihouse = getIHours(item.id);

          const priceItem = Number(
            getPrice(item.id, item.qty).replace(/\D/g, '')
          );
          setTotalSort2(
            (prev) =>
              prev +
              priceItem +
              pricePlanung * phouse * item.qty +
              priceMontage * ihouse * item.qty
          );
        }
      }

      if (item.id === '1036' || item.id === '1477' || item.id === '1037') {
        setSort3((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        setTotalSort3(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (
        item.side === 'sideA' &&
        (item.id === '1059' ||
          item.id === '1117' ||
          item.id === '1096' ||
          item.id === '1546')
      ) {
        setSort4((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            undefined,
            item.section,
            item.sectionLenght
          ).replace(/\D/g, '')
        );
        setTotalSort4(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (
        item.side === 'sideB' &&
        (item.id === '1059' ||
          item.id === '1117' ||
          item.id === '1096' ||
          item.id === '1546')
      ) {
        setSort5((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            undefined,
            item.section,
            item.sectionLenght
          ).replace(/\D/g, '')
        );
        setTotalSort5(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (
        item.side === 'sideC' &&
        (item.id === '1059' ||
          item.id === '1117' ||
          item.id === '1096' ||
          item.id === '1546')
      ) {
        setSort6((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            undefined,
            item.section,
            item.sectionLenght
          ).replace(/\D/g, '')
        );
        setTotalSort6(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (
        item.side === 'sideD' &&
        (item.id === '1059' ||
          item.id === '1117' ||
          item.id === '1096' ||
          item.id === '1546')
      ) {
        setSort7((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            undefined,
            item.section,
            item.sectionLenght
          ).replace(/\D/g, '')
        );
        setTotalSort7(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (
        item.side === 'sideA' &&
        (item.id === '1553' ||
          item.id === '1025' ||
          item.id === '1554' ||
          item.id === '1134' ||
          item.id === '1135' ||
          item.id === '1136' ||
          item.id === '1137' ||
          item.id === '1045' ||
          item.id === '1049' ||
          item.id === '1048' ||
          item.id === '1040' ||
          item.id === '1083' ||
          item.id === '1081' ||
          item.id === '1087' ||
          item.id === '1084' ||
          item.id === '1085' ||
          item.id === '1041' ||
          item.id === '1042' ||
          item.id === '1058')
      ) {
        setSort8((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        let priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            item.section,
            undefined,
            undefined,
            item.squar
          ).replace(/\D/g, '')
        );

        if (item.squar) {
          priceItem *= item.squar;
        }

        setTotalSort8(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (
        item.side === 'sideB' &&
        (item.id === '1553' ||
          item.id === '1025' ||
          item.id === '1554' ||
          item.id === '1134' ||
          item.id === '1135' ||
          item.id === '1136' ||
          item.id === '1137' ||
          item.id === '1045' ||
          item.id === '1049' ||
          item.id === '1048' ||
          item.id === '1040' ||
          item.id === '1083' ||
          item.id === '1081' ||
          item.id === '1087' ||
          item.id === '1084' ||
          item.id === '1085' ||
          item.id === '1041' ||
          item.id === '1042' ||
          item.id === '1058')
      ) {
        setSort9((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        let priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            item.section,
            undefined,
            undefined,
            item.squar
          ).replace(/\D/g, '')
        );

        if (item.squar) {
          priceItem *= item.squar;
        }

        setTotalSort9(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (
        item.side === 'sideC' &&
        (item.id === '1553' ||
          item.id === '1025' ||
          item.id === '1554' ||
          item.id === '1134' ||
          item.id === '1135' ||
          item.id === '1136' ||
          item.id === '1137' ||
          item.id === '1045' ||
          item.id === '1049' ||
          item.id === '1048' ||
          item.id === '1040' ||
          item.id === '1083' ||
          item.id === '1081' ||
          item.id === '1087' ||
          item.id === '1084' ||
          item.id === '1085' ||
          item.id === '1041' ||
          item.id === '1042' ||
          item.id === '1058')
      ) {
        setSort10((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        let priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            item.section,
            undefined,
            undefined,
            item.squar
          ).replace(/\D/g, '')
        );

        if (item.squar) {
          priceItem *= item.squar;
        }

        setTotalSort10(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (
        item.side === 'sideD' &&
        (item.id === '1553' ||
          item.id === '1025' ||
          item.id === '1554' ||
          item.id === '1134' ||
          item.id === '1135' ||
          item.id === '1136' ||
          item.id === '1137' ||
          item.id === '1045' ||
          item.id === '1049' ||
          item.id === '1048' ||
          item.id === '1040' ||
          item.id === '1083' ||
          item.id === '1081' ||
          item.id === '1087' ||
          item.id === '1084' ||
          item.id === '1085' ||
          item.id === '1041' ||
          item.id === '1042' ||
          item.id === '1058')
      ) {
        setSort11((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        let priceItem = Number(
          getPrice(
            item.id,
            item.qty,
            item.side,
            item.section,
            undefined,
            undefined,
            item.squar
          ).replace(/\D/g, '')
        );

        if (item.squar) {
          priceItem *= item.squar;
        }

        setTotalSort11(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (item.id === '1547' || item.id === '1250') {
        setSort12((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        setTotalSort12(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (
        item.id === '1180' ||
        item.id === '1165' ||
        item.id === '1184' ||
        item.id === '1321' ||
        item.id === '1099' ||
        item.id === '1245' ||
        item.id === '1291' ||
        item.id === '1292' ||
        item.id === '1293'
      ) {
        setSort13((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        setTotalSort13(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (item.id === '1367' || item.id === '1548') {
        setSort14((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        setTotalSort14(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (item.id === '1090') {
        setSort15((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        setTotalSort15(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
      if (item.id === '1551' || item.id === '1544') {
        setSort16((prev) => [...prev, item]);
        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        setTotalSort16((prev) => prev + priceItem);
      }
      if (item.id === '1094' || item.id === '1097') {
        setSort17((prev) => [...prev, item]);
        const phouse = getPHours(item.id);
        const ihouse = getIHours(item.id);

        const priceItem = Number(
          getPrice(item.id, item.qty).replace(/\D/g, '')
        );
        setTotalSort17(
          (prev) =>
            prev +
            priceItem +
            pricePlanung * phouse * item.qty +
            priceMontage * ihouse * item.qty
        );
      }
    });
  };

  useEffect(() => {
    groupPrice();
    setPriceUpdate(true);
  }, [order]);

  const format = (str) => {
    if (!str) return 0;
    const res = str.replace(/(\d)(?=(\d{3})+(\D|$))/g, `$1'`);
    return res;
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {typeModel === 'louver' ? (
        <table>
          <tbody>
            {sort1.length > 0 ? (
              <tr style={{ width: '371px', textAlign: 'left' }}>
                <th style={{ width: '241px', textAlign: 'left' }}>
                  Lamellendach
                </th>
                <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
                <th style={{ width: '100px', textAlign: 'right' }}>
                  {`${format(String(Math.round(totalSort1)))}.-`}
                </th>
              </tr>
            ) : null}
            {sort1.map((item) => (
              <React.Fragment key={item + Math.random()}>
                {item.id === '1061'
                  ? new Array(item.qty).fill(0).map(() => (
                      <tr
                        colSpan="3"
                        key={Math.random()}
                        style={{
                          width: '371px',
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                      >
                        <th
                          style={{
                            fontWeight: '300',
                            textAlign: 'left',
                          }}
                          colSpan={3}
                        >
                          {getName(item.id)}
                        </th>
                      </tr>
                    ))
                  : null}
              </React.Fragment>
            ))}
            <div
              style={{
                display: 'flex',
                gap: '5px',
                flexDirection: 'column',
                fontWeight: '300',
              }}
            >
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                Breite {source.globalWidth / 10} cm
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                Tiefe {source.globalDepth / 10} cm
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                Höhe {source.globalHeight1 / 10} cm
              </div>
            </div>
          </tbody>
        </table>
      ) : null}

      {typeModel === 'louver2' ? (
        <table>
          <tbody>
            {sort1.length > 0 ? (
              <tr style={{ width: '371px', textAlign: 'left' }}>
                <th style={{ width: '241px', textAlign: 'left' }}>
                  Lamellendach
                </th>
                <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
                <th style={{ width: '100px', textAlign: 'right' }}>
                  {`${format(String(Math.round(totalSort1)))}.-`}
                </th>
              </tr>
            ) : null}
            {sort1.map((item) => (
              <React.Fragment key={item + Math.random()}>
                {item.id === '1064'
                  ? new Array(item.qty).fill(0).map(() => (
                      <tr
                        colSpan="3"
                        key={Math.random()}
                        style={{
                          width: '371px',
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                      >
                        <th
                          style={{
                            fontWeight: '300',
                            textAlign: 'left',
                          }}
                          colSpan={3}
                        >
                          {getName(item.id)}
                        </th>
                      </tr>
                    ))
                  : null}
              </React.Fragment>
            ))}
            <div
              style={{
                display: 'flex',
                gap: '5px',
                flexDirection: 'column',
                fontWeight: '300',
              }}
            >
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                Breite {source.globalWidth / 10} cm
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                Tiefe {source.globalDepth / 10} cm
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                Höhe {source.globalHeight1 / 10} cm
              </div>
            </div>
          </tbody>
        </table>
      ) : null}

      {typeModel === 'glass' ? (
        <table>
          <tbody>
            {sort2.length > 0 ? (
              <tr>
                <th style={{ width: '241px', textAlign: 'left' }}>Glasdach</th>
                <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
                <th style={{ width: '100px', textAlign: 'right' }}>{`${format(
                  String(Math.round(totalSort2))
                )}.-`}</th>
              </tr>
            ) : null}
            {sort2.map((item) => (
              <React.Fragment key={item + Math.random()}>
                {item.id === '1001' || item.id === '1055'
                  ? new Array(item.qty).fill(0).map(() => (
                      <tr
                        key={Math.random()}
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                      >
                        <th
                          style={{
                            fontWeight: '300',
                            textAlign: 'left',
                          }}
                          colSpan={3}
                        >
                          {getName(item.id)}
                        </th>
                      </tr>
                    ))
                  : null}
              </React.Fragment>
            ))}
            <div
              style={{
                display: 'flex',
                gap: '5px',
                flexDirection: 'column',
                fontWeight: '300',
              }}
            >
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                Breite {source.globalWidth / 10} cm
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                Tiefe {source.globalDepth / 10} cm
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                Höhe Wandanschluss {source.globalHeight2 / 10} cm
              </div>
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                Höhe Durchgang {source.globalHeight1 / 10} cm
              </div>
            </div>
          </tbody>
        </table>
      ) : null}

      <table>
        <tbody>
          {sort3.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Dachbeschattung
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort3)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort3.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1036' || item.id === '1477' || item.id === '1037'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort4.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Beschattung Seite links
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort4)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort4.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1059' ||
              item.id === '1117' ||
              item.id === '1096' ||
              item.id === '1546'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort5.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Beschattung Vorne
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort5)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort5.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1059' ||
              item.id === '1117' ||
              item.id === '1096' ||
              item.id === '1546'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort6.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Beschattung Seite rechts
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort6)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort6.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1059' ||
              item.id === '1117' ||
              item.id === '1096' ||
              item.id === '1546'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort7.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Beschattung Rückseite
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort7)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort7.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1059' ||
              item.id === '1117' ||
              item.id === '1096' ||
              item.id === '1546'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort8.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Verglasung Seite links
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort8)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort8.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1553' ||
              item.id === '1025' ||
              item.id === '1554' ||
              item.id === '1134' ||
              item.id === '1135' ||
              item.id === '1136' ||
              item.id === '1137' ||
              item.id === '1045' ||
              item.id === '1049' ||
              item.id === '1048' ||
              item.id === '1040' ||
              item.id === '1083' ||
              item.id === '1081' ||
              item.id === '1087' ||
              item.id === '1084' ||
              item.id === '1085' ||
              item.id === '1041' ||
              item.id === '1042' ||
              item.id === '1058'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort9.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Verglasung Vorne
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort9)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort9.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1553' ||
              item.id === '1025' ||
              item.id === '1554' ||
              item.id === '1134' ||
              item.id === '1135' ||
              item.id === '1136' ||
              item.id === '1137' ||
              item.id === '1045' ||
              item.id === '1049' ||
              item.id === '1048' ||
              item.id === '1040' ||
              item.id === '1083' ||
              item.id === '1081' ||
              item.id === '1087' ||
              item.id === '1084' ||
              item.id === '1085' ||
              item.id === '1041' ||
              item.id === '1042' ||
              item.id === '1058'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort10.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Verglasung Seite rechts
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort10)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort10.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1553' ||
              item.id === '1025' ||
              item.id === '1554' ||
              item.id === '1134' ||
              item.id === '1135' ||
              item.id === '1136' ||
              item.id === '1137' ||
              item.id === '1045' ||
              item.id === '1049' ||
              item.id === '1048' ||
              item.id === '1040' ||
              item.id === '1083' ||
              item.id === '1081' ||
              item.id === '1087' ||
              item.id === '1084' ||
              item.id === '1085' ||
              item.id === '1041' ||
              item.id === '1042' ||
              item.id === '1058'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort11.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Verglasung Rückseite
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort11)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort11.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1553' ||
              item.id === '1025' ||
              item.id === '1554' ||
              item.id === '1134' ||
              item.id === '1135' ||
              item.id === '1136' ||
              item.id === '1137' ||
              item.id === '1045' ||
              item.id === '1049' ||
              item.id === '1048' ||
              item.id === '1040' ||
              item.id === '1083' ||
              item.id === '1081' ||
              item.id === '1087' ||
              item.id === '1084' ||
              item.id === '1085' ||
              item.id === '1041' ||
              item.id === '1042' ||
              item.id === '1058'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort12.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>Beleuchtung</th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(Math.round(totalSort12))))}.-`}
              </th>
            </tr>
          ) : null}

          {sort12.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1547' || item.id === '1250'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort13.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>Steuerung</th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort13)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort13.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1180' ||
              item.id === '1165' ||
              item.id === '1184' ||
              item.id === '1321' ||
              item.id === '1099' ||
              item.id === '1245' ||
              item.id === '1291' ||
              item.id === '1292' ||
              item.id === '1293'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort14.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>Zubehör</th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort14)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort14.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1367' || item.id === '1548'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort15.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>Baugesuch</th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort15)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort15.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1090'
                ? new Array(item.qty).fill(0).map(() => (
                    <tr
                      key={Math.random()}
                      style={{
                        fontWeight: '300',
                        textAlign: 'left',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: '300',
                          textAlign: 'left',
                        }}
                        colSpan={3}
                      >
                        {getName(item.id)}
                      </th>
                    </tr>
                  ))
                : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort16.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Dienstleistungen
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort16)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort16.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1551' || item.id === '1544' ? (
                <tr
                  style={{
                    fontWeight: '300',
                    textAlign: 'left',
                  }}
                >
                  <th
                    style={{
                      fontWeight: '300',
                      textAlign: 'left',
                    }}
                    colSpan={3}
                  >
                    {getName(item.id)}
                  </th>
                </tr>
              ) : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          {sort17.length > 0 ? (
            <tr>
              <th style={{ width: '241px', textAlign: 'left' }}>
                Fremdleistungen
              </th>
              <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
              <th style={{ width: '100px', textAlign: 'right' }}>
                {`${format(String(Math.round(totalSort17)))}.-`}
              </th>
            </tr>
          ) : null}

          {sort17.map((item) => (
            <React.Fragment key={item + Math.random()}>
              {item.id === '1094' || item.id === '1097' ? (
                <tr
                  key={Math.random()}
                  style={{
                    fontWeight: '300',
                    textAlign: 'left',
                  }}
                >
                  <th
                    style={{
                      fontWeight: '300',
                      textAlign: 'left',
                    }}
                    colSpan={3}
                  >
                    {getName(item.id)}
                  </th>
                </tr>
              ) : null}
            </React.Fragment>
          ))}
        </tbody>
      </table>

      <table>
        <tbody>
          <tr style={{ width: '375px', height: '20px' }} />

          <tr>
            <th style={{ width: '241px', textAlign: 'left' }}>
              Kostenschätzung exkl. MwSt.
            </th>
            <th style={{ width: '30px', textAlign: 'right' }}>Fr.</th>
            <th style={{ width: '100px', textAlign: 'right' }}>
              {`${format(
                String(
                  Math.round(
                    totalSort1 +
                      totalSort2 +
                      totalSort3 +
                      totalSort4 +
                      totalSort5 +
                      totalSort6 +
                      totalSort7 +
                      totalSort8 +
                      totalSort9 +
                      totalSort10 +
                      totalSort11 +
                      totalSort12 +
                      totalSort13 +
                      totalSort14 +
                      totalSort15 +
                      totalSort16 +
                      totalSort17
                  )
                )
              )}.-`}
            </th>
          </tr>
        </tbody>
      </table>

      <br />

      {!expertMode ? (
        <div
          style={{
            fontWeight: '700',
            textAlign: 'left',
          }}
        >
          {title > 0
            ? 'Ihre unverbindliche Kostenschätzung exkl. Planung und Montage'
            : 'Ihre unverbindliche Kostenschätzung'}
        </div>
      ) : (
        <div
          style={{
            fontWeight: '700',
            textAlign: 'left',
          }}
        >
          Ihre unverbindliche Kostenschätzung exkl. Planung, Baugesuch, Aufmass
          und Montage durch unsere Thermogreen Montageprofis. <br /> <br /> Auf
          Anfrage können wir Ihr Lamellendach liefern und montieren. Jetzt
          unverbindlich anfragen. Unsere Montageprofis installieren Ihr
          Lamellen- oder Glasdach gerne.
        </div>
      )}
    </>
  );
};

export default Order;
