import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/ui/Button';

import {
  setShowLedModal,
  setShowModelSizeModal,
  setShowInfoModal,
} from '../../../store/reducers/User/UserSlice';

import options9 from '../../../assets/options/led1.jpg';
import options10 from '../../../assets/options/led2.jpg';
import options11 from '../../../assets/options/sensor-1.jpg';
import options12 from '../../../assets/options/sensor-2.jpg';

import options13 from '../../../assets/options/sensor-3.jpg';
import options14 from '../../../assets/options/sensor-4.jpg';

import options15 from '../../../assets/options/elektro-1.jpg';
import options16 from '../../../assets/options/elektro-2.jpg';

import options17 from '../../../assets/options/handsensor-1.jpg';
import options18 from '../../../assets/options/handsensor-2.jpg';
import options19 from '../../../assets/options/handsensor-3.jpg';

import options20 from '../../../assets/options/spots.jpg';
import infoIcon from '../../../assets/info.svg';

import InfoButton from '../Elemente/InfoButton';

const Zubehor = () => {
  const dispatch = useDispatch();
  const { source, typeModel } = useSelector((state) => state.project);

  const [sideCurrent, setSideCurrent] = useState(null);
  const [typeLed, setTypeLed] = useState(null);

  const [typeSteering, setTypeSteering] = useState(null);
  const [typeElectro, setTypeElectro] = useState(null);
  const [typeRemoteControl, setTypeRemoteControl] = useState(null);

  const handleShowLedModal = (slug) => {
    if (!sideCurrent) {
      toast.warn('Objekt auswählen');
      return;
    }
    source.funcLedVariable();
    dispatch(setShowLedModal(slug));
  };

  const steering = (el, type) => {
    if (!sideCurrent) {
      toast.warn('Objekt auswählen');
      return;
    }
    source.funcToggleSteering(el, type);
    // if (steeringTypes.includes(type)) {
    //   console.log(`Type ${type} already exists`);
    // } else {
    //   dispatch(addSteeringType(type));
    //   source.funcToggleSteering(el, type);
    // }
  };
  const electro = (el, type) => {
    if (!sideCurrent) {
      toast.warn('Objekt auswählen');
      return;
    }
    source.funcToggleElectro(el, type);
    // dispatch(updateSideFilled(selectedSideModel, true));
  };
  const remoteControl = (el, type) => {
    if (!sideCurrent) {
      toast.warn('Objekt auswählen');
      return;
    }
    source.funcToggleRemoteControl(el, type);
  };

  useEffect(() => {
    if (!source) return;
    source?.addEventListener('check-side', (data) => {
      setSideCurrent(data.current);
      setTypeLed(data.led?.[0]?.type);
      setTypeSteering(data?.steering);
      setTypeElectro(data?.electro);
      setTypeRemoteControl(data?.remoteControl[0]);
    });

    source?.addEventListener('edit-open', (data) => {
      dispatch(setShowModelSizeModal(data.value));
    });
    source?.addEventListener('wall-disable', () => {
      toast.warn('Sie können auf dieser Seite keine Wände hinzufügen');
    });
  }, [source]);

  return (
    <>
      <div className="accordion-item ">
        <div className="accordion-item-title">
          LED
          <InfoButton
            title="LED"
            text="Wählen Sie zwischen Direkt und Indirekt, oder wählen Sie beides aus. LED-Streifen schaffen stimmungsvolle Effekte und eine gemütliche Atmosphäre."
            link={undefined}
          />
          <Button
            className="info-icon-btn"
            onClick={() => {
              dispatch(
                setShowInfoModal({
                  title: 'LED',
                  text: 'Wählen Sie zwischen Direkt und Indirekt, oder wählen Sie beides aus. LED-Streifen schaffen stimmungsvolle Effekte und eine gemütliche Atmosphäre.',
                  link: '',
                })
              );
            }}
          >
            <img src={infoIcon} alt="infoIcon" />
          </Button>
        </div>

        <div className="accordion-item-content">
          {typeModel === 'louver' || typeModel === 'louver2' ? (
            <div className="accordion-option-img">
              <Button
                className={
                  typeLed === 'direct'
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  handleShowLedModal('direct');
                }}
              >
                <img
                  src={options9}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">LED Direkt</div>
            </div>
          ) : null}
          {typeModel === 'louver' || typeModel === 'louver2' ? (
            <div className="accordion-option-img">
              <Button
                className={
                  typeLed === 'inderect'
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  handleShowLedModal('inderect');
                }}
              >
                <img
                  src={options10}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">LED Indirekt</div>
            </div>
          ) : null}
          {typeModel === 'glass' ? (
            <div className="accordion-option-img">
              <Button
                className={
                  typeLed === 'spots'
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  handleShowLedModal('spots');
                }}
              >
                <img
                  src={options20}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">LED Spots</div>
            </div>
          ) : null}
        </div>

        <div className="line-or-accordion"> </div>
      </div>

      {typeModel === 'louver' || typeModel === 'louver2' ? (
        <div className="accordion-item ">
          <div className="accordion-item-title">
            Steuerung
            <InfoButton
              title="Steuerung"
              text="Wünschen Sie eine automatische Steuerung bei Regen und/oder Wind?"
              link={undefined}
            />
            <Button
              className="info-icon-btn"
              onClick={() => {
                dispatch(
                  setShowInfoModal({
                    title: 'Steuerung',
                    text: 'Wünschen Sie eine automatische Steuerung bei Regen und/oder Wind?',
                    link: '',
                  })
                );
              }}
            >
              <img src={infoIcon} alt="infoIcon" />
            </Button>
          </div>
          <div className="accordion-item-content">
            <div className="accordion-option-img ">
              <Button
                className={
                  typeSteering?.find((item) => item === 'steeringType1')
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  steering(undefined, 'steeringType1');
                }}
              >
                <img
                  src={options11}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">Regensensor</div>
            </div>
            <div className="accordion-option-img ">
              <Button
                className={
                  typeSteering?.find((item) => item === 'steeringType2')
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  steering(undefined, 'steeringType2');
                }}
              >
                <img
                  src={options12}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">Windsensor</div>
            </div>
          </div>
          <div className="line-or-accordion"> </div>
        </div>
      ) : null}

      {typeModel === 'glass' ? (
        <div className="accordion-item ">
          <div className="accordion-item-title">
            Steuerung
            <InfoButton
              title="Steuerung"
              text="Wünschen Sie eine automatische Steuerung bei Sonne/Wind und/oder Regen?"
              link={undefined}
            />
            <Button
              className="info-icon-btn"
              onClick={() => {
                dispatch(
                  setShowInfoModal({
                    title: 'Steuerung',
                    text: 'Wünschen Sie eine automatische Steuerung bei Sonne/Wind und/oder Regen?',
                    link: '',
                  })
                );
              }}
            >
              <img src={infoIcon} alt="infoIcon" />
            </Button>
          </div>
          <div className="accordion-item-content">
            <div className="accordion-option-img ">
              <Button
                className={
                  typeSteering?.find((item) => item === 'steeringType3')
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  steering(undefined, 'steeringType3');
                }}
              >
                <img
                  src={options13}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">Sonnen/Wind Sensor</div>
            </div>
            <div className="accordion-option-img ">
              <Button
                className={
                  typeSteering?.find((item) => item === 'steeringType4')
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  steering(undefined, 'steeringType4');
                }}
              >
                <img
                  src={options14}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">
                Regensensor mit Temperaturfühler
              </div>
            </div>
          </div>
          <div className="line-or-accordion"> </div>
        </div>
      ) : null}

      {typeModel === 'louver' ? (
        <div className="accordion-item ">
          <div className="accordion-item-title">
            Elektro
            <InfoButton
              title="Elektro"
              text="Wünschen Sie elektrisches Zubehör in Form einer Steckdose in der Stütze?"
              link={undefined}
            />
            <Button
              className="info-icon-btn"
              onClick={() => {
                dispatch(
                  setShowInfoModal({
                    title: 'Elektro',
                    text: 'Wünschen Sie elektrisches Zubehör in Form einer Steckdose in der Stütze?',
                    link: '',
                  })
                );
              }}
            >
              <img src={infoIcon} alt="infoIcon" />
            </Button>
          </div>
          <div className="accordion-item-content">
            <div className="accordion-option-img ">
              <Button
                className={
                  typeElectro?.find((item) => item === 'electroType1')
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  electro(undefined, 'electroType1');
                }}
              >
                <img
                  src={options15}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">
                Steckdose in Stütze integriert
              </div>
            </div>
            {/* скрытый элемент */}
            <div className="accordion-option-img " style={{ display: 'none' }}>
              <Button
                className={
                  typeElectro?.find((item) => item === 'electroType2')
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  electro(undefined, 'electroType2');
                }}
              >
                <img
                  src={options16}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">
                Regensensor mit Temperatursensor
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {typeModel === 'glass' ? (
        <div className="accordion-item ">
          <div className="accordion-item-title">
            Handsender
            <InfoButton
              title="Handsender"
              text="Wählen Sie zwischen einem 1- oder 5- Kanal Handsender oder einem 4-Kanal Handsender mit Sensor Schalter."
              link={undefined}
            />
            <Button
              className="info-icon-btn"
              onClick={() => {
                dispatch(
                  setShowInfoModal({
                    title: 'Handsender',
                    text: 'Wählen Sie zwischen einem 1- oder 5- Kanal Handsender oder einem 4-Kanal Handsender mit Sensor Schalter.',
                    link: '',
                  })
                );
              }}
            >
              <img src={infoIcon} alt="infoIcon" />
            </Button>
          </div>
          <div className="accordion-item-content">
            <div className="accordion-option-img">
              <Button
                className={
                  typeRemoteControl === 'remoteControlType1'
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  remoteControl(undefined, 'remoteControlType1');
                }}
              >
                <img
                  src={options17}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">
                Somfy 1-Kanal Handsender
              </div>
            </div>
            <div className="accordion-option-img ">
              <Button
                className={
                  typeRemoteControl === 'remoteControlType2'
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  remoteControl(undefined, 'remoteControlType2');
                }}
              >
                <img
                  src={options18}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">
                Somfy 5-Kanal Handsender
              </div>
            </div>
            <div className="accordion-option-img ">
              <Button
                className={
                  typeRemoteControl === 'remoteControlType3'
                    ? 'accordion-option-content active'
                    : 'accordion-option-content'
                }
                onClick={() => {
                  remoteControl(undefined, 'remoteControlType3');
                }}
              >
                <img
                  src={options19}
                  alt=""
                  style={{ width: '94px', height: '69px' }}
                />
              </Button>
              <div className="accordion-option-title">
                Somfy 4-Kanal Handsender mit Sensor Schalter
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Zubehor;
