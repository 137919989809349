import {
  Object3D,
  Box3,
  Vector3,
  MeshBasicMaterial,
  PlaneGeometry,
  Mesh,
  BoxGeometry,
  EdgesGeometry,
  LineSegments,
  LineBasicMaterial,
  MeshStandardMaterial,
} from 'three';

export default class Element extends Object3D {
  name = 'model';
  raycasterElMesh = [];
  arrZip = [];
  arrLamellen = [];
  arrLed = [];
  arrWindow = [];

  arrSteering = [];
  arrElectro = [];
  arrRemoteControl = [];

  buffArrWindow = [];
  bb = new Object3D();
  zip = new Object3D();
  window = new Object3D();
  isElement = true;
  position2d = {
    x: 0,
    z: 0,
  };

  colSectionZip = {
    sideA: 0,
    sideB: 0,
    sideC: 0,
    sideD: 0,
  };
  colSectionWindow = {
    sideA: 0,
    sideB: 0,
    sideC: 0,
    sideD: 0,
  };
  colSectionLamellen = {
    sideA: [0, 0],
    sideB: [0, 0],
    sideC: [0, 0],
    sideD: [0, 0],
  };

  lamellenWidth = {
    sideA: 1,
    sideB: 1,
    sideC: 1,
    sideD: 1,
  };

  constructor(
    model,
    onload = () => {},
    position = new Vector3(),
    visibleBb = true,
    wallSideD = false,
    expertMode = true
  ) {
    super();
    this.visibleBb = visibleBb;
    this.expertMode = expertMode;
    this.onload = onload;
    this.position.copy(position);
    this.add(this.bb);
    this.bb.name = 'bb';
    this.init(model);
    this.wallSideD = wallSideD;
  }

  update(model, onload2) {
    this.onload = () => {};
    this.onload2 = onload2;
    this.remove(this.model);
    this.init(model);
  }

  async init(model) {
    try {
      this.model = model;
      this.add(this.model);

      setTimeout(() => {
        if (!this.bb.children.length) {
          this.createBox();
        } else {
          this.updateBox();
        }
        this.onload();
        this.onload2 && this.onload2();
      }, 100);
    } catch (eror) {
      console.log(eror);
    }
  }
  updateBox() {
    ///////////Zip
    if (this.model.y1 < 1) {
      let filtered = this.arrZip.filter((el) => el.side === 'sideRoof');
      this.arrZip = filtered;
    }

    if (this.model.x < 1 + 0.4 && this.model.type === 'louver') {
      let filtered = this.arrZip.filter(
        (el) =>
          el.side === 'sideA' || el.side === 'sideC' || el.side === 'sideRoof'
      );
      this.arrZip = filtered;
    }
    if (this.model.x < 1 + 0.3 && this.model.type === 'louver2') {
      let filtered = this.arrZip.filter(
        (el) =>
          el.side === 'sideA' || el.side === 'sideC' || el.side === 'sideRoof'
      );
      this.arrZip = filtered;
    }
    if (this.model.x < 1 + 0.2 && this.model.type === 'glass') {
      let filtered = this.arrZip.filter(
        (el) =>
          el.side === 'sideA' || el.side === 'sideC' || el.side === 'sideRoof'
      );
      this.arrZip = filtered;
    }
    if (this.model.z < 1 + 0.2 && this.model.type === 'louver') {
      let filtered = this.arrZip.filter(
        (el) =>
          el.side === 'sideB' || el.side === 'sideD' || el.side === 'sideRoof'
      );
      this.arrZip = filtered;
    }
    if (this.model.z < 1 + 0.1 && this.model.type === 'louver2') {
      let filtered = this.arrZip.filter(
        (el) =>
          el.side === 'sideB' || el.side === 'sideD' || el.side === 'sideRoof'
      );
      this.arrZip = filtered;
    }
    if (this.model.z < 1 + 0.135 && this.model.type === 'glass') {
      let filtered = this.arrZip.filter(
        (el) =>
          el.side === 'sideB' || el.side === 'sideD' || el.side === 'sideRoof'
      );
      this.arrZip = filtered;
    }
    if (this.model.x < 1 && this.model.type === 'glass') {
      let filtered = this.arrZip.filter((el) => el.side !== 'sideRoof');
      this.arrZip = filtered;
    }
    if (this.model.z < 1 && this.model.type === 'glass') {
      let filtered = this.arrZip.filter((el) => el.side !== 'sideRoof');
      this.arrZip = filtered;
    }
    if (this.model.y1 > 3.4 + 0.2 && this.model.type === 'louver') {
      let filtered = this.arrZip.filter(
        (el) => el.side === 'sideRoof' || el.type === 'zipType2'
      );
      this.arrZip = filtered;
    }
    if (this.model.y1 > 3.4 + 0.2 - 0.14 && this.model.type === 'louver') {
      let filtered = this.arrZip.filter(
        (el) => el.side === 'sideRoof' || el.type === 'zipType1'
      );
      this.arrZip = filtered;
    }
    if (this.model.y1 > 3.4 + 0.1 - 0.14 && this.model.type === 'louver2') {
      let filtered = this.arrZip.filter(
        (el) => el.side === 'sideRoof' || el.type === 'zipType1'
      );
      this.arrZip = filtered;
    }
    if (this.model.y1 > 4 + 0.15 && this.model.type === 'glass') {
      let filtered = this.arrZip.filter((el) => el.side === 'sideRoof');
      this.arrZip = filtered;
    }
    if (this.model.x > 5.5 && this.model.type === 'glass') {
      let temp = this.arrZip.filter(
        (el) =>
          el.side === 'sideB' &&
          (el.type === 'zipType1' || el.type === 'zipType3')
      );
      let filtered = this.arrZip.filter(
        (item) =>
          !temp.find((el) => el.type === item.type && el.side === item.side)
      );
      this.arrZip = filtered;
    }

    this.arrZip.forEach((zip) => {
      this.showZip(zip.side, zip.type);
    });

    ///////////Window
    if (this.model.y1 < 1) {
      this.arrWindow = [];
    }
    if (this.model.x < 1 + 0.4 && this.model.type === 'louver') {
      let filtered = this.arrWindow.filter(
        (el) => el.side === 'sideA' || el.side === 'sideC'
      );
      this.arrWindow = filtered;
    }
    if (this.model.x < 1 + 0.2 && this.model.type === 'glass') {
      let filtered = this.arrWindow.filter(
        (el) => el.side === 'sideB' || el.side === 'sideD'
      );
      this.arrWindow = filtered;
    }
    if (this.model.z < 1 + 0.2 && this.model.type === 'louver') {
      let filtered = this.arrWindow.filter(
        (el) => el.side === 'sideB' || el.side === 'sideD'
      );
      this.arrWindow = filtered;
    }
    if (this.model.z < 1 + 0.135 && this.model.type === 'glass') {
      let filtered = this.arrWindow.filter(
        (el) => el.side === 'sideA' || el.side === 'sideC'
      );
      this.arrWindow = filtered;
    }
    if (this.model.y1 > 3 + 0.2 && this.model.type === 'louver') {
      let filtered = this.arrWindow.filter((el) => el.type !== 'windowType1');
      this.arrWindow = filtered;
    }
    if (this.model.y1 > 3 + 0.2 && this.model.type === 'louver') {
      let filtered = this.arrWindow.filter((el) => el.type !== 'windowType2');
      this.arrWindow = filtered;
    }
    if (this.model.y2 > 4 + 0.15 && this.model.type === 'glass') {
      let filtered = this.arrWindow.filter((el) => el.type !== 'windowType1');
      this.arrWindow = filtered;
    }
    if (this.model.y1 > 2.4 + 0.15 && this.model.type === 'glass') {
      let filtered = this.arrWindow.filter((el) => el.type !== 'windowType2');
      this.arrWindow = filtered;
    }

    this.arrWindow.forEach((window) => {
      this.showWindow(window.side, window.type);
    });

    //////////Lamellen
    if (this.model.y > 3 + 0.2 && this.model.type === 'louver') {
      this.arrLamellen = [];
    }
    if (this.model.x > 6.98 + 0.4 && this.model.type === 'louver') {
      let filtered = this.arrLamellen.filter(
        (el) => el.side !== 'sideB' && el.side !== 'sideD'
      );
      this.arrLamellen = filtered;
    }
    if (this.model.z > 6.98 + 0.2 && this.model.type === 'louver') {
      let filtered = this.arrLamellen.filter(
        (el) => el.side !== 'sideC' && el.side !== 'sideA'
      );
      this.arrLamellen = filtered;
    }

    if (this.model.y > 3 + 0.2 && this.model.type === 'louver2') {
      this.arrLamellen = [];
    }
    if (this.model.x > 6.98 + 0.3 && this.model.type === 'louver2') {
      let filtered = this.arrLamellen.filter(
        (el) => el.side !== 'sideB' && el.side !== 'sideD'
      );
      this.arrLamellen = filtered;
    }
    if (this.model.z > 6.98 + 0.1 && this.model.type === 'louver2') {
      let filtered = this.arrLamellen.filter(
        (el) => el.side !== 'sideC' && el.side !== 'sideA'
      );
      this.arrLamellen = filtered;
    }

    this.arrLamellen.forEach((lammelen) => {
      this.showLamellen(lammelen.side, lammelen.type);
    });

    this.bbox = new Box3();
    this.model.scale.set(1.001, 1.001, 1.001);
    this.model.position.sub(this.position);
    this.bbox.setFromObject(this.model, true);
    const size = (this.size = new Vector3());
    this.bbox.getSize(size);
    const center = new Vector3(
      (this.bbox.max.x + this.bbox.min.x) / 2,
      (this.bbox.max.y + this.bbox.min.y) / 2,
      (this.bbox.max.z + this.bbox.min.z) / 2
    );
    this.model.scale.set(1, 1, 1);
    this.model.position.set(0, 0, 0);

    this.position.x = this.model.z * -this.position2d.x;
    this.position.z = this.model.x * this.position2d.z;

    const geometryRoof = new PlaneGeometry(size.x, size.z);
    this.planeRoof.geometry.copy(geometryRoof);
    this.planeRoof.position.copy(center);
    this.planeRoof.position.y = this.bbox.max.y;

    const geometrySideA = new PlaneGeometry(size.x, size.y);
    this.planeSideA.geometry.copy(geometrySideA);
    this.planeSideA.position.copy(center);
    this.planeSideA.position.z = this.bbox.max.z;
    const geometryWallA = new BoxGeometry(size.x + 0.21, size.y + 0.15, 0.1);
    this.planeSideA.userData.wall.geometry.copy(geometryWallA);
    this.planeSideA.userData.wall.position.copy(this.planeSideA.position);
    this.planeSideA.userData.wall.position.z += 0.06;
    this.planeSideA.userData.wall.position.y += 0.075;

    const geometrySideB = new PlaneGeometry(size.z, size.y);
    this.planeSideB.geometry.copy(geometrySideB);
    this.planeSideB.position.copy(center);
    this.planeSideB.position.x = this.bbox.max.x;
    const geometryWallB = new BoxGeometry(size.z + 0.21, size.y + 0.15, 0.1);
    this.planeSideB.userData.wall.geometry.copy(geometryWallB);
    this.planeSideB.userData.wall.position.copy(this.planeSideB.position);
    this.planeSideB.userData.wall.position.x += 0.055;
    this.planeSideB.userData.wall.position.y += 0.075;

    const geometrySideC = new PlaneGeometry(size.x, size.y);
    this.planeSideC.geometry.copy(geometrySideC);
    this.planeSideC.position.copy(center);
    this.planeSideC.position.z = this.bbox.min.z;
    const geometryWallC = new BoxGeometry(size.x + 0.21, size.y + 0.15, 0.1);
    this.planeSideC.userData.wall.geometry.copy(geometryWallC);
    this.planeSideC.userData.wall.position.copy(this.planeSideC.position);
    this.planeSideC.userData.wall.position.z -= 0.06;
    this.planeSideC.userData.wall.position.y += 0.075;

    const geometrySideD = new PlaneGeometry(size.z, size.y);
    this.planeSideD.geometry.copy(geometrySideD);
    this.planeSideD.position.copy(center);
    this.planeSideD.position.x = this.bbox.min.x;
    const geometryWallD = new BoxGeometry(size.z + 0.21, size.y + 0.15, 0.1);
    this.planeSideD.userData.wall.geometry.copy(geometryWallD);
    this.planeSideD.userData.wall.position.copy(this.planeSideD.position);
    this.planeSideD.userData.wall.position.x -= 0.055;
    this.planeSideD.userData.wall.position.y += 0.075;

    const geometryEdges = new BoxGeometry(size.x, size.y, size.z);
    const edges = new EdgesGeometry(geometryEdges);

    this.edges.geometry.copy(edges);
    this.edges.position.copy(center);
  }
  createBox() {
    this.bbox = new Box3();
    this.model.scale.set(1.001, 1.001, 1.001);
    this.model.position.sub(this.position);
    this.bbox.setFromObject(this.model, true);
    const size = (this.size = new Vector3());
    this.bbox.getSize(size);
    const center = new Vector3(
      (this.bbox.max.x + this.bbox.min.x) / 2,
      (this.bbox.max.y + this.bbox.min.y) / 2,
      (this.bbox.max.z + this.bbox.min.z) / 2
    );
    this.model.scale.set(1, 1, 1);
    this.model.position.set(0, 0, 0);
    const materialSide = new MeshBasicMaterial({
      color: 0x61c38b,
      opacity: 0.2,
      transparent: true,
      side: 2,
    });

    const geometryRoof = new PlaneGeometry(size.x, size.z);
    this.planeRoof = new Mesh(geometryRoof, materialSide.clone());
    this.planeRoof.rotation.x += Math.PI / 2;
    this.planeRoof.position.copy(center);
    this.planeRoof.position.y = this.bbox.max.y;
    this.planeRoof.name = 'sideRoof';
    this.raycasterElMesh.push(this.planeRoof);
    this.planeRoof.renderOrder = 3;
    this.bb.add(this.planeRoof);

    const geometrySideA = new PlaneGeometry(size.x, size.y);
    this.planeSideA = new Mesh(geometrySideA, materialSide.clone());
    this.planeSideA.position.copy(center);
    this.planeSideA.position.z = this.bbox.max.z;
    this.planeSideA.name = 'sideA';
    this.planeSideA.userData.wall = this.createWall(
      size.x,
      size.y,
      this.planeSideA.position,
      this.planeSideA.name
    );
    this.raycasterElMesh.push(this.planeSideA);
    this.planeSideA.renderOrder = 3;
    this.planeSideA.new2dPosition = () => {
      return {
        x: this.position2d.x,
        z: this.position2d.z + 1,
      };
    };
    this.bb.add(this.planeSideA);

    const geometrySideB = new PlaneGeometry(size.z, size.y);
    this.planeSideB = new Mesh(geometrySideB, materialSide.clone());
    this.planeSideB.rotation.y += Math.PI / 2;
    this.planeSideB.position.copy(center);
    this.planeSideB.position.x = this.bbox.max.x;
    this.planeSideB.name = 'sideB';
    this.planeSideB.userData.wall = this.createWall(
      size.z,
      size.y,
      this.planeSideB.position,
      this.planeSideB.name
    );
    this.raycasterElMesh.push(this.planeSideB);
    this.planeSideB.renderOrder = 3;
    this.planeSideB.new2dPosition = () => {
      return {
        x: this.position2d.x - 1,
        z: this.position2d.z,
      };
    };
    this.bb.add(this.planeSideB);

    const geometrySideC = new PlaneGeometry(size.x, size.y);
    this.planeSideC = new Mesh(geometrySideC, materialSide.clone());
    this.planeSideC.position.copy(center);
    this.planeSideC.position.z = this.bbox.min.z;
    this.planeSideC.name = 'sideC';
    this.planeSideC.userData.wall = this.createWall(
      size.x,
      size.y,
      this.planeSideC.position,
      this.planeSideC.name
    );
    this.raycasterElMesh.push(this.planeSideC);
    this.planeSideC.renderOrder = 3;
    this.planeSideC.new2dPosition = () => {
      return {
        x: this.position2d.x,
        z: this.position2d.z - 1,
      };
    };
    this.bb.add(this.planeSideC);

    const geometrySideD = new PlaneGeometry(size.z, size.y);
    this.planeSideD = new Mesh(geometrySideD, materialSide.clone());
    this.planeSideD.rotation.y += Math.PI / 2;
    this.planeSideD.position.copy(center);
    this.planeSideD.position.x = this.bbox.min.x;
    this.planeSideD.name = 'sideD';
    this.planeSideD.userData.wall = this.createWall(
      size.z,
      size.y,
      this.planeSideD.position,
      this.planeSideD.name
    );
    this.raycasterElMesh.push(this.planeSideD);
    this.planeSideD.renderOrder = 3;
    this.planeSideD.new2dPosition = () => {
      return {
        x: this.position2d.x + 1,
        z: this.position2d.z,
      };
    };
    this.bb.add(this.planeSideD);

    this.edges = this.addEdges(
      size.x,
      size.y,
      size.z,
      center,
      this.model.rotation
    );

    if (this.expertMode !== false) {
      this.edges.visible = false;
      this.bb.visible = false;
    }
    this.bb.add(this.edges);
    if (this.expertMode) return;
    this.bb.visible = this.visibleBb;
  }
  addEdges(x, y, z, position, rotation) {
    const geometryEdges = new BoxGeometry(x, y, z);
    const edges = new EdgesGeometry(geometryEdges);
    this.linesegments = new LineSegments(
      edges,
      new LineBasicMaterial({
        color: 0x000000,
        opacity: 1,
        transparent: true,
        side: 2,
      })
    );
    this.linesegments.name = 'linesegments';
    this.linesegments.rotation.copy(rotation);
    this.linesegments.position.copy(position);
    this.linesegments.renderOrder = -1;

    return this.linesegments;
  }
  createWall(x, y, position, side) {
    const geometry = new BoxGeometry(x + 0.21, y + 0.15, 0.1);
    const material = new MeshStandardMaterial({
      color: 0xe7e7e7,
    });

    material.name = 'wallmaterial';

    const wall = new Mesh(geometry, material);
    wall.position.copy(position);
    if (side === 'sideA') {
      wall.position.z += 0.06;
    }
    if (side === 'sideB') {
      wall.position.x += 0.055;
      wall.rotation.y += Math.PI / 2;
    }
    if (side === 'sideC') {
      wall.position.z -= 0.06;
    }
    if (side === 'sideD') {
      wall.position.x -= 0.055;
      wall.rotation.y += Math.PI / 2;

      if (this.wallSideD) {
        this.add(wall);
      }
    }
    wall.position.y += 0.075;
    return wall;
  }
  toggleWall(side, value) {
    const { name } = side;
    if (!['sideA', 'sideB', 'sideC', 'sideD'].includes(name)) return;
    if (typeof value !== 'undefined') {
      if (!value && side.userData.wall.parent) {
        side.userData.wall.parent.remove(side.userData.wall);
      } else if (value) {
        this.add(side.userData.wall);
      }
      return;
    }
  }
  toggleLed(el, options) {
    const prev = el.arrLed[0];
    if (el.arrLed.length > 0) {
      el.arrLed = [];
      el.arrLed.push(options);
    } else {
      el.arrLed.push(options);
    }
    const next = options;
    return {
      prev: prev,
      next: next,
    };
  }
  toggleSteering(el, type, typeprev) {
    if (!type) {
      type = typeprev;
    }
    if (el.arrSteering.find((item) => item === type)) {
      el.arrSteering = el.arrSteering.filter((el) => el !== type);
      return {
        prev: type,
        next: undefined,
      };
    } else {
      el.arrSteering.push(type);
      return {
        prev: undefined,
        next: type,
      };
    }
  }
  toggleElectro(el, type, typeprev) {
    if (!type) {
      type = typeprev;
    }
    if (el.arrElectro.find((item) => item === type)) {
      el.arrElectro = el.arrElectro.filter((el) => el !== type);
      return {
        prev: type,
        next: undefined,
      };
    } else {
      el.arrElectro.push(type);
      return {
        prev: undefined,
        next: type,
      };
    }
  }
  toggleRemoteControl(el, type) {
    const prev = el.arrRemoteControl[0];
    if (el.arrRemoteControl.length > 0) {
      el.arrRemoteControl = [];
      el.arrRemoteControl.push(type);
    } else {
      el.arrRemoteControl.push(type);
    }
    const next = type;
    return {
      prev: prev,
      next: next,
    };
  }
  toggleWindow(side, type) {
    const { name } = side;
    const prev = this.arrWindow.find((el) => el.side === name)?.type;
    if (!['sideA', 'sideB', 'sideC', 'sideD'].includes(name)) return;
    if (side.parent.parent.model[prev]?.userData[side.name]?.parent) {
      side.parent.parent.model[prev].userData[side.name].parent.remove(
        side.parent.parent.model[prev].userData[side.name]
      );
      this.arrWindow = this.arrWindow.filter((item) => item.side !== side.name);
    }
    if (type) {
      this.model.windowObj.add(
        side.parent.parent.model[type].userData[side.name]
      );
      this.arrWindow.push({ side: name, type: type });
    }
    return {
      prev: prev,
      next: type,
    };
  }
  toggleZip(side, type) {
    const { name } = side;
    if (!['sideA', 'sideB', 'sideC', 'sideD', 'sideRoof'].includes(name))
      return;
    const prev = this.arrZip.find((el) => el.side === name)?.type;
    side.parent.parent.model.zipType1?.userData[name]?.parent?.remove(
      side.parent.parent.model.zipType1?.userData[name]
    );
    side.parent.parent.model.zipType2?.userData[name]?.parent?.remove(
      side.parent.parent.model.zipType2?.userData[name]
    );
    side.parent.parent.model.zipType3?.userData[name]?.parent?.remove(
      side.parent.parent.model.zipType3?.userData[name]
    );
    side.parent.parent.model.zipType4?.userData[name]?.parent?.remove(
      side.parent.parent.model.zipType4?.userData[name]
    );
    this.arrZip = this.arrZip.filter((el) => el.side !== name);

    if (type) {
      this.model.zipObj.add(this.model[type].userData[name]);
      this.arrZip.push({ side: name, type: type });
    }

    this.update(this.model, this.expertMode, () => {
      if (this.model.bias) {
        this.position.x += this.position2d.x * this.model.bias.x;
        this.position.z -= this.position2d.z * this.model.bias.z;
      }
    });
    return {
      prev: prev,
      next: type,
    };
  }
  toggleLamellen(side, type) {
    const { name } = side;
    if (!['sideA', 'sideB', 'sideC', 'sideD'].includes(name)) return;

    const prev = this.arrLamellen.find((el) => el.side === name)?.type;
    side.parent.parent.model.lamellenType1?.userData[name]?.parent?.remove(
      side.parent.parent.model.lamellenType1?.userData[name]
    );
    this.arrLamellen = this.arrLamellen.filter((el) => el.side !== name);

    if (type) {
      this.model.zipObj.add(this.model[type].userData[name]);
      this.arrLamellen.push({ side: name, type: type });
    }

    this.update(this.model, this.expertMode, () => {
      if (this.model.bias) {
        this.position.x += this.position2d.x * this.model.bias.x;
        this.position.z -= this.position2d.z * this.model.bias.z;
      }
    });
    return {
      prev: prev,
      next: type,
    };
  }
  showZip(side, type) {
    if (!this.arrZip.find((item) => item.side === side)) {
      this.arrZip.push({ side, type });
    }
    this.model.zipObj.add(this.model[type].userData[side]);
    // this.update(this.model, () => {
    //   if (this.model.bias) {
    //     this.position.x += this.position2d.x * this.model.bias.x;
    //     this.position.z -= this.position2d.z * this.model.bias.z;
    //   }
    // });
  }
  showWindow(side, type) {
    if (!this.arrWindow.find((item) => item.side === side)) {
      this.arrWindow.push({ side, type });
    }
    this.model.windowObj.add(this.model[type].userData[side]);
  }
  showLamellen(side, type) {
    if (!this.arrLamellen.find((item) => item.side === side)) {
      this.arrLamellen.push({ side, type });
    }
    this.model.lamellenObj.add(this.model[type].userData[side]);
  }
  attachMenu(menu, side = 'planeRoof', ziproof = false) {
    if (side === 'sideRoof' && !ziproof) {
      this.deleteMenu(menu);
      return;
    }

    if (side === 'sideA') side = 'planeSideA';
    if (side === 'sideB') side = 'planeSideB';
    if (side === 'sideC') side = 'planeSideC';
    if (side === 'sideD') side = 'planeSideD';
    if (side === 'sideRoof') side = 'planeRoof';

    menu.position.copy(this[side].position);
    if (side === 'planeRoof') {
      menu.position.y += 0.2;
    }

    if (side === 'planeSideA') {
      menu.position.z += 0.76;
    }
    if (side === 'planeSideB') {
      menu.position.x += 0.76;
    }
    if (side === 'planeSideC') {
      menu.position.z -= 0.76;
    }
    if (side === 'planeSideD') {
      menu.position.x -= 0.76;
    }
    this.add(menu);
  }
  updateMenu(menu, side = 'planeRoof', ziproof = false) {
    if (side === 'sideRoof' && !ziproof) {
      this.deleteMenu(menu);
      return;
    }

    if (side === 'sideA') side = 'planeSideA';
    if (side === 'sideB') side = 'planeSideB';
    if (side === 'sideC') side = 'planeSideC';
    if (side === 'sideD') side = 'planeSideD';

    if (side === 'sideRoof') side = 'planeRoof';

    menu.position.copy(this[side].position);
    if (side === 'planeRoof') {
      menu.position.y += 0.2;
    }

    if (side === 'planeSideA') {
      menu.position.z += 0.76;
    }
    if (side === 'planeSideB') {
      menu.position.x += 0.76;
    }
    if (side === 'planeSideC') {
      menu.position.z -= 0.76;
    }
    if (side === 'planeSideD') {
      menu.position.x -= 0.76;
    }
  }
  deleteMenu(menu, elements) {
    menu.traverse((item) => {
      if (item.geometry) {
        item.geometry.dispose();
      }
    });

    if (elements) {
      elements.forEach((item) => {
        item.remove(menu);
      });
    } else {
      this.remove(menu);
    }
  }
}
